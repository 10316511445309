import React, {Component} from "react";
import {Button} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import FeedRepository from "../services/FeedRepository";
import postMapper, {getImagesCard, getMessageCard, getVideoCard} from "../helpers/PostHelper";

export default class UserPostsTable extends Component {
    constructor(props) {
        super(props);

        this.openPet = this.props.openPet.bind(this)
        this.goToPetButtonFormatter = this.goToPetButtonFormatter.bind(this);
        this.webviewButtonFormatter = this.webviewButtonFormatter.bind(this);
        this.actionButtomFormatter = this.actionButtomFormatter.bind(this);
        this.userId = this.props.userId;

        this.state = {
            isLoading: false,
            data: [],
            deletePostDialog: false,
            deletePostId: null,
            nextToken: null
        };

        this.columns = [{
            dataField: 'id',
            text: 'Id',
            hidden: true
        }, {
            dataField: 'created',
            text: 'Created'
        }, {
            dataField: 'updated',
            text: 'Updated',
            hidden: true
        }, {
            dataField: 'type',
            text: 'Type'
        }, {
            dataField: 'message',
            text: 'Message'
        }, {
            dataField: 'images',
            text: 'Images',
            hidden: true
        }, {
            dataField: 'video',
            text: 'Video',
            hidden: true
        }, {
            dataField: 'petTag',
            text: 'Pet',
            hidden: true
        }, {
            dataField: 'location',
            text: 'Location',
            hidden: true
        }, {
            dataField: 'sharelink',
            text: 'Webview',
            hidden: true
        }, {
            dataField: 'likes',
            text: 'Likes'
        }, {
            dataField: 'comments',
            text: 'Comments'
        }, {
            dataField: 'button',
            text: 'Actions',
            formatter: this.actionButtomFormatter
        }]

        this.expandRow = {
            renderer: row => (
                <Container>
                    <Row className="show-grid">
                        <Col md={6}>
                            <p>Id: <label>{row.id}</label></p>
                            <p>Created: <label>{row.created}</label></p>
                            <p>Updated: <label>{row.updated}</label></p>
                            <p>Share link: <Button className="btn-sm" href={row.sharelink}
                                                   target={"_blank"}>Open</Button></p>
                        </Col>
                        <Col md={6}>
                            <p>Type: <label>{row.type}</label></p>
                            <p>Likes: <label>{row.likes}</label></p>
                            <p>Comments: <label>{row.comments}</label></p>
                            <p>Pet: {this.getPet(row.petTag)}</p>
                        </Col>
                    </Row>
                    <Col>
                        {getMessageCard(row)}
                        {getImagesCard(row)}
                        {getVideoCard(row)}
                    </Col>
                </Container>

            ),
            showExpandColumn: true,
            expandByColumnOnly: true
        };
    }

    goToPetButtonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm" onClick={() => this.openPet(row.id)}>{row.id}</Button>
    }

    webviewButtonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm" onClick={() => this.openPet(row.id)}>{row.id}</Button>
    }

    actionButtomFormatter(cell, row, rowIndex, formatExtraData) {
        return <div>
            <Button variant="outline-danger" size="lg"
                    onClick={() => this.setState({deletePostDialog: true, deletePostId: row.id})}>Delete post</Button>
        </div>
    }

    videoButtonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm" onClick={() => this.openPet(row.id)}>Play video</Button>
    }

    componentDidMount() {
        this.loadMorePosts();
    }

    getMessage = (val) => {
        if (val.message) {
            return val.message.message;
        }
        return '';
    }

    getPet = (petTag) => {
        if (petTag) {
            return <Button className="btn-sm" onClick={() => this.openPet(petTag.petId)}>{petTag.petName}</Button>;
        }
        return <h3><Badge variant="secondary">Pet not marked</Badge></h3>;
    }

    handleClose = () => {
        this.setState({deletePostDialog: false, deletePostId: null});
    }

    deletePost = () => {
        if (this.state.deletePostId) {
            FeedRepository.deletePost(this.state.deletePostId).then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                this.handleClose();
                this.clear();
            }).catch(error => {
                alert(error);
            })
        }
    }

    clear = () => {
        this.setState({
                data: [],
                nextToken: null
            },
            this.loadMorePosts);
    }

    loadMoreButton = () => {
        if (this.state.nextToken) {
            return (
                <Button className="btn-lg" onClick={() => this.loadMorePosts()}>Load more posts</Button>
            )
        }
    }

    loadMorePosts = () => {
        this.setState({isLoading: true});
        FeedRepository.getUserPosts(this.userId, this.state.nextToken).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            var tableData = data.items.map(function (val) {
                if (val.type == 'POST') {
                    return postMapper(val);
                }
            })
            this.setState({
                data: [...this.state.data, ...tableData],
                nextToken: data.nextToken
            });
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    render() {
        let button = this.loadMoreButton()
        return (
            <div>
                {button}
                <BootstrapTable
                    keyField="id"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={true}
                    striped
                    hover
                    noDataIndication={this.emptyIndication}
                    expandRow={this.expandRow}
                />
                <Modal style={{opacity: 1}} show={this.state.deletePostDialog} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete {this.state.deletePostId}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-danger" size="lg" onClick={this.deletePost}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={this.handleClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}