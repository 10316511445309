import userRequest from "./RequestService";

class ReviewRepository {
    getUserReviews(userId) {
        return userRequest('/notification/api/support/users/' + userId + '/reviews', {
            method: 'GET'
        })
    }
}

export default new ReviewRepository();