import userRequest from "./RequestService";

class InviteRepository {
    getPetInvites(petId) {
        return userRequest('/invite/api/support/pet/' + petId + '/users', {
            method: 'GET'
        })
    }

    changeToPetParent(inviteId) {
        return userRequest('/invite/api/support/invites/'+inviteId, {
            method: 'PUT',
            body: JSON.stringify({
                newRelation: 'OWNED'
            })
        })
    }

    changeToGuardian(inviteId) {
        return userRequest('/invite/api/support/invites/'+inviteId, {
            method: 'PUT',
            body: JSON.stringify({
                newRelation: 'SHARED'
            })
        })
    }
}

export default new InviteRepository();