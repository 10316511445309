import userAuthHeader from "./auth-header";
import AuthService from "./auth.service";

const API_URL = "https://api.pawscout.com:18999/api/shopify/"

class OrderRepository {

    startScript(type, maxDate) {
        let options = {
            method: 'GET'
        }
        options.headers = userAuthHeader();
        let url = API_URL + type + "?maxDate=" + maxDate;
        return fetch(url, options)
            .then(response => {
                if (response.ok) {
                    return response;
                }
                if (response.status == 401) {
                    return AuthService.refresh().then(response => {
                        options.headers = userAuthHeader();
                        return fetch(url, options).then(val => {
                            return val;
                        });
                    })
                } else if (response.status == 403) {
                    throw new Error("You are not authorized to do this action")
                } else {
                    throw new Error("Unknown error, can't load data");
                }
            })
    }
}

export default new OrderRepository();