import React, {Component} from "react";
import ReportRepository from "../../services/ReportRepository";
import {Button} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

export default class RemovedUserTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            rows: []
        }

        this.columns = [{
            dataField: 'id',
            text: 'User Id'
        }, {
            dataField: 'email',
            text: 'Email'
        }, {
            dataField: 'delete_date',
            text: 'Remove date'
        }, {
            dataField: 'status',
            text: 'Status'
        }, {
            dataField: 'progress',
            text: 'Progress'
        }, {
            dataField: 'notes',
            text: 'Notes'
        }]
    }

    componentDidMount() {
        this.loadRemovedUsers();
    }

    loadRemovedUsers = () => {
        this.setState({isLoading: true}, () => {
            ReportRepository.getRemovedUsers()
                .then(response => {
                    if (!response.ok)
                        throw Error(response.statusText);
                    return response.json()
                }).then(data => {
                let tableData = []
                if (typeof data.data !== 'undefined' && data.data.length > 0) {
                    tableData = data.data.map(function (val) {
                        return {
                            id: val.userId,
                            email: val.email,
                            delete_date: val.removeDate,
                            status: val.status,
                            progress: val.progress + '/' + val.totalSteps,
                            notes: val.note
                        }
                    });
                }
                this.setState({
                    rows: tableData
                })
            }).finally(() => {
                this.setState({isLoading: false})
            })
        })
    }

    render() {
        return (
            <div>
                <Button
                    onClick={() => this.loadRemovedUsers()}>
                    Refresh
                </Button>
                <br />
                <BootstrapTable
                    keyField="id"
                    data={this.state.rows}
                    columns={this.columns}
                    //remote={{ filter: true, pagination: true, sort: true}}
                    deleteRow={false}
                    striped
                    hover
                    condensed
                    defaultSorted={[{
                        dataField: 'delete_date',
                        order: 'desc'
                    }]}
                    noDataIndication={this.emptyIndication
                    }
                />
            </div>
        )
    }
}