import userRequest from "./RequestService";

class FreeBatteryRepository {
    getUserOrders(userId) {
        return userRequest('/email/api/support/users/'+userId+'/freeBatteries', {
            method: 'GET'
        })
    }
}

export default new FreeBatteryRepository();