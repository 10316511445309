import React, {Component} from "react";
import {Button, Col, Row} from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container"
import BootstrapTable from "react-bootstrap-table-next";
import InviteRepository from "../../services/InviteRepository";
import PetRepository from "../../services/PetRepository";

export default class PetRelationTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            petRelations: [],
            petId: this.props.petId
        }

        this.goToUserButtonFormatter = this.goToUserButtonFormatter.bind(this);
        this.reloadParent = this.props.reloadParent.bind(this);
        this.actionsFormatter = this.actionsFormatter.bind(this);
        this.relationColumns = [{
            dataField: 'id',
            text: 'Invite ID'
        }, {
            dataField: 'userId',
            text: 'User ID',
            formatter: this.goToUserButtonFormatter
        }, {
            dataField: 'name',
            text: 'Name'
        }, {
            dataField: 'relation',
            text: 'Relation'
        }, {
            dataField: 'status',
            text: 'Status'
        }, {
            dataField: 'contact',
            text: 'External contact'
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: this.actionsFormatter
        }]

        this.handleAddSelectedUser = this.props.handleAddSelectedUser.bind(this);
        this.downloadData();
    }

    goToUserButtonFormatter(cell, row, rowIndex, formatExtraData) {
        if (row.userId) {
            return <Button className="btn-sm"
                           onClick={() => this.handleAddSelectedUser(row.userId)}>{row.userId}</Button>
        } else {
            return <h3><Badge variant="secondary">External invite</Badge></h3>
        }
    }

    handleMakeGuardianButton(row) {
        if (row.id == null) {
            return;
        }

        InviteRepository.changeToGuardian(row.id).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.downloadData()
        }).catch(error => {
            alert(error);
        });
    };

    handleMakePetOwnerButton(row) {
        if (row.userId == null) {
            return;
        }

        PetRepository.changePetOwner(this.state.petId, row.userId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.reloadParent()
        }).catch(error => {
            alert(error);
        });
    };

    handleRemoveRelation(petId, userId) {
        if (petId == null || userId == null) {
            return
        }

    };

    handleMakePetParentButton(row) {
        if (row.id == null) {
            return;
        }

        InviteRepository.changeToPetParent(row.id).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.downloadData()
        }).catch(error => {
            alert(error);
        });
    };

    handleRemoveRelation(petId, userId) {
        if (petId == null || userId == null) {
            return
        }

    };

    actionsFormatter(cell, row, rowIndex, formatExtraData) {
        if (row.relation == 'OWNED') {
            return <div>
                <Container>
                    <Row className="show-grid">
                        <Col>
                            <Button className="btn-sm"
                                    onClick={() => this.handleMakeGuardianButton(row)}>Change relation to
                                guardian</Button>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col>
                            <Button className="btn-sm"
                                    onClick={() => this.handleMakePetOwnerButton(row)}>Mark as Pet
                                Owner</Button>
                        </Col>
                    </Row>
                    {/*<Row className="show-grid">*/}
                    {/*    <Col>*/}
                    {/*        <Button className="btn-sm" variant="outline-danger"*/}
                    {/*                onClick={() => this.handleRemoveRelation(row.id, row.userId)}>Remove</Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
            </div>
        } else {
            return <div>
                <Container>
                    <Row className="show-grid">
                        <Col>
                            <Button className="btn-sm"
                                    onClick={() => this.handleMakePetParentButton(row)}>Change relation
                                to
                                pet parent</Button> </Col></Row>
                    {/*<Row className="show-grid">*/}
                    {/*    <Col>*/}
                    {/*        <Button className="btn-sm" variant="outline-danger"*/}
                    {/*                onClick={() => this.handleRemoveRelation(row.id, row.userId)}>Remove</Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
            </div>
        }
    }


    downloadData = () => {
        this.setState({isLoading: true});
        let utils = this;
        InviteRepository.getPetInvites(this.state.petId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            let ownerInvites = [];
            let guardianInvites = [];
            if (data.owners) {
                ownerInvites = data.owners.map(val => utils.mapInvite(val))
            }
            if (data.guardians) {
                guardianInvites = data.guardians.map(val => utils.mapInvite(val))
            }
            let tableData = [...ownerInvites, ...guardianInvites]
            this.setState({petRelations: tableData})
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    mapInvite = (invite) => {
        var userId = null;
        var userName = '';
        if (invite.invitedUser) {
            userId = invite.invitedUser.id;
            userName = invite.invitedUser.name;
        }
        return {
            id: invite.id,
            userId: userId,
            name: userName,
            relation: invite.relation,
            status: invite.status,
            contact: invite.contact
        }
    }

    render() {
        return (
            <div>
                <BootstrapTable
                    keyField="id"
                    data={this.state.petRelations}
                    columns={this.relationColumns}
                    remote={true}
                    deleteRow={true}
                    striped
                    hover
                    condensed
                    noDataIndication={this.emptyIndication}
                />
            </div>

        );
    }
}