import React from "react";
import {Route, Switch} from "react-router-dom";
import Login from "./containers/Login";
import Home from "./containers/Home";
import Report from "./containers/Report"
import Image from "./containers/Image"
import SupportView from "./containers/support/SupportView";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import FeedView from "./containers/feed/FeedView";
import Backend from "./containers/backend/Backend";
import OrderView from "./containers/order/OrderView";
import StatisticsView from "./containers/stats/StatisticsView";
import RemovedUsersView from "./containers/user/RemovedUsersView";

export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps}/>
        <AuthenticatedRoute path="/support" exact component={SupportView} props={childProps}/>
        <AuthenticatedRoute path="/feed" exact component={FeedView} props={childProps}/>
        <AuthenticatedRoute path="/image" exact component={Image} props={childProps}/>
        <AuthenticatedRoute path="/report" exact component={Report} props={childProps}/>
        <AuthenticatedRoute path="/order" exact component={OrderView} props={childProps}/>
        <AuthenticatedRoute path="/backend" exact component={Backend} props={childProps}/>
        <AuthenticatedRoute path="/statistics" exact component={StatisticsView} props={childProps}/>
        <AuthenticatedRoute path="/removedUsers" exact component={RemovedUsersView} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound}/>
    </Switch>;