import React, {Component} from "react";
import {Card} from "react-bootstrap";
import BackendTestRepository from "../../services/BackendTestRepository";
import Badge from "react-bootstrap/Badge";
export default class Backend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            latestReport: null
        };
    }

    componentDidMount() {
        this.loadLatestReport();
    }

    loadLatestReport = () => {
        this.setState({isLoading: true});
        BackendTestRepository.latestResults().then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.setState({latestReport: data})
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    printLatestResult = () => {
        if (this.state.latestReport) {

            const items = this.state.latestReport.positions.map((position) => (
                <p>
                    <label>{position.service}</label>
                    {position.results.map((result) => <p>{result.testName}: {result.testResult == 'SUCCESS'? <Badge variant="success">{result.testResult}</Badge>:<Badge variant="danger">{result.testResult}</Badge>}</p>)}
                    <br/>
                </p>
            ))

            return (
                <div><p>Last backend test
                    run: <label>{this.state.latestReport.date} UTC</label> ({this.state.latestReport.ageMinutes} minutes
                    ago)</p>
                    {items}
                </div>
            )
        }
        return <div></div>
    }

    render() {
        return (
            <div>
                <Card id="latest-result-test">
                    <Card.Header>Latest backend test results</Card.Header>
                    <Card.Body>
                        {this.printLatestResult()}
                    </Card.Body>
                </Card>
            </div>
        );
    }
}