import userRequest from "./RequestService";

class FeedRepository {
    getFeed(token) {
        return userRequest('/social/api/v1/feed', {
            method: 'PUT',
            body: JSON.stringify({
                    token: token
                }
            )
        })
    }

    findPostsByHashtag(hashtag) {
        return userRequest('/social/api/v1/feed', {
            method: 'PUT',
            body: JSON.stringify({
                    conditions: [{
                        type: 'HASH_TAG',
                        value: hashtag
                    }]
                }
            )
        })
    }

    getUserPosts(userId, nextToken) {
        return userRequest('/social/api/v3/posts/users', {
            method: 'PUT',
            body: JSON.stringify({
                    token: nextToken,
                    id: userId
                }
            )
        })
    }

    deletePost(postId) {
        return userRequest('/social/api/system/posts/' + postId, {
            method: 'DELETE'
        });
    }

    getPost(postId) {
        return userRequest('/social/api/v3/posts/' + postId, {
            method: 'GET'
        })
    }

    getPostComments(postId) {
        return userRequest('/social/api/v3/posts/' + postId + '/comments', {
            method: 'GET'
        })
    }

    findPostOrComment(uuid) {
        return userRequest('/social/api/system/find/' + uuid, {
            method: 'GET'
        })
    }

    deletePostComment(postId, commentId) {
        return userRequest('/social/api/system/posts/' + postId + '/comments/' + commentId, {
            method: 'DELETE'
        })
    }

    removePostImage(postId, imageId) {
        return userRequest('/social/api/system/posts/' + postId + '/images/' + imageId, {
            method: 'DELETE'
        })
    }

    removeCommentImage(typeId, imageId) {
        return userRequest('/social/api/system/posts/' + typeId + '/images/' + imageId, {
            method: 'DELETE'
        })
    }
}

export default new FeedRepository();