import React, {Component} from 'react';
import RemovedUserTable from "./RemovedUserTable";

export default class RemovedUsersView extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div>
                <RemovedUserTable/>
            </div>
        )
    }
}