import React, {Component} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "./MobileTable.css";
import MobileRepository from "../services/MobileRepository";
import convertToDateTime from "../helpers/convert";
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ModalForm from "../components/ModalForm";
import BeaconRepository from "../services/BeaconRepository";

export default class MobileTable extends Component {
    constructor(props) {
        super(props);

        this.userId = this.props.userId;

        this.state = {
            isLoading: false,
            data: [],
            registerDeviceDialog: false,
            registerDeviceId: null,
            tagId: ''
        };

        this.actionButtomFormatter = this.actionButtomFormatter.bind(this);
        //was const columns!
        this.columns = [{
            dataField: 'id',
            text: 'Device ID'
        }, {
            dataField: 'platform',
            text: 'Platform'
        }, {
            dataField: 'appVersion',
            text: 'App version'
        }, {
            dataField: 'notification',
            text: 'Notifications enabled'
        }, {
            dataField: 'ts',
            text: 'Last active'
        }, {
            dataField: 'lastLocation',
            text: 'Last location'
        }, {
            dataField: 'button',
            text: 'Register',
            formatter: this.actionButtomFormatter
        }];
    }

    componentDidMount() {
        this.loadDevices();
    }

    clear = () => {
        this.setState({
            data: []
        });
    }

    actionButtomFormatter(cell, row, rowIndex, formatExtraData) {
        return <div>
            <Button variant="info" size="lg"
                    onClick={() => this.setState({registerDeviceDialog: true, registerDeviceId: row.id})}>Register</Button>
        </div>
    }

    emptyIndication() {
        return "No data";
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    loadDevices = () => {
        this.setState({isLoading: true});
        let util = this;
        MobileRepository.getUserDevices(this.userId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            if (typeof data.data !== 'undefined' && data.data.length > 0) {
                var tableData = data.data.map(function (val) {
                    let lastTs = convertToDateTime(val.ts);
                    return {
                        id: val.id,
                        platform: val.platform,
                        appVersion: val.appVersion,
                        notification: val.notification,
                        ts: lastTs,
                        lastLocation: val.lastLocation
                    }
                });
                this.setState({
                    data: tableData
                });
            } else {
                this.setState({
                    data: []
                });
            }
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    handleClose = () => {
        this.setState({registerDeviceDialog: false, registerDeviceId: null});
    }

    registerDevice = (tagId, name) => {
        // console.log(tagId);
        // console.log(name);
        // console.log(this.state.registerDeviceId);
        BeaconRepository.registerDevice(tagId, this.state.registerDeviceId, name).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
        }).catch(error => {
            alert(error);
        })
        this.handleClose();
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        return (
            <div>
                <BootstrapTable
                    keyField="id"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={true}
                    striped
                    hover
                    condensed
                    noDataIndication={this.emptyIndication}
                />
                {this.state.registerDeviceDialog ?
                    <ModalForm
                        closeModal={this.handleClose}
                        isOpen={this.state.registerDeviceDialog}
                        handleSubmit={this.registerDevice}
                        /> : null
                }
            </div>

        );
    }
}