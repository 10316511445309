import React, {Component} from "react";
import NewProcessingModal from "./NewProcessingModal";
import {Button} from "react-bootstrap";
import OrderRepository from "../../services/OrderRepository";

export default class OrderView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addNewItem: false
        }
    };

    handleClose = () => {
        this.setState({addNewItem: false});
    }

    zeroPad = (num, places) => String(num).padStart(places, '0');

    triggerAddNew = (type, maxDate) => {
        // console.log(type);
        // console.log(selectedDate);
        let formattedDate = maxDate.getFullYear()+"-"+this.zeroPad((maxDate.getMonth() + 1), 2)+"-"+maxDate.getDate();
        OrderRepository.startScript(type, formattedDate)
            .then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response.text()
            }).then(data => {
                alert(data);
            }).catch(error => {
                alert(error);
            }).finally(() => this.handleClose())

    }


    render() {
        return (<div>
            <Button variant="info" size="lg" onClick={() => this.setState({addNewItem: true})}>Process new
                orders</Button>
            {this.state.addNewItem ? <NewProcessingModal
                closeModal={this.handleClose}
                isOpen={this.state.addNewItem}
                handleSubmit={this.triggerAddNew}
            /> : null}
        </div>)
    }
}