import userRequest from "./RequestService";

class ReportRepository {
    getTemplateList() {
        return userRequest('/statistic/api/support/admin/template', {
            method: 'GET'});
    }

    addNewReport(body) {
        return userRequest('/statistic/api/support/admin/report', {
            method: 'POST',
            body: JSON.stringify(body)
        })
    }

    refresh() {
        return userRequest('/statistic/api/support/admin/report', {
            method: 'GET'});
    }

    reportDownload(id) {
        return userRequest('/statistic/api/support/admin/report/' + id + '/download', {
            method: 'GET'});
    }

    getStatsFor(stats) {
        return userRequest('/statistic/api/support/admin/statistic?stats='+stats, {
            method: 'GET'});
    }

    scheduleUserDelete(userId, body) {
        return userRequest('/statistic/api/support/admin/users/'+userId, {
            method: 'DELETE',
            body: JSON.stringify(body)});
    }

    getRemovedUsers() {
        return userRequest('/statistic/api/support/admin/users', {
            method: 'GET'});
    }
}

export default new ReportRepository();