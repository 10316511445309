import React, {Component} from "react";
import FreeBatteryRepository from "../../services/FreeBatteryRepository";
import BootstrapTable from "react-bootstrap-table-next";
import convertToDateTime from "../../helpers/convert";

export default class FreeBatteryOrdersTable extends Component {
    constructor(props) {
        super(props);

        this.userId = this.props.userId;

        this.state = {
            isLoading: false,
            data: []
        };

        this.createdDateFormatter = this.createdDateFormatter.bind(this);
        this.columns = [{
            dataField: 'id',
            text: 'ID',
            hidden: true
        }, {
            dataField: 'createdDate',
            text: 'Created date',
            formatter: this.createdDateFormatter
        }, {
            dataField: 'name',
            text: 'Name'
        }, {
            dataField: 'address',
            text: 'Address'
        }, {
            dataField: 'city',
            text: 'City'
        }, {
            dataField: 'state',
            text: 'State'
        }, {
            dataField: 'zip',
            text: 'ZIP'
        }, {
            dataField: 'country',
            text: 'Country'
        }, {
            dataField: 'email',
            text: 'E-mail'
        }, {
            dataField: 'status',
            text: 'Status'
        }, {
            dataField: 'discountCode',
            text: 'Discount Code'
        }, {
            dataField: 'shopifyOrderId',
            text: 'Shopify Order ID'
        }]
    }

    createdDateFormatter(cell, row, rowIndex, formatExtraData) {
        return convertToDateTime(row.createdDate)
    }

    componentDidMount() {
        this.loadFreeBatteryOrders()
    }

    emptyIndication() {
        return "No data";
    }

    loadFreeBatteryOrders = () => {
        this.setState({isLoading: true});
        FreeBatteryRepository.getUserOrders(this.userId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            if (typeof data.data !== 'undefined' && data.data.length > 0) {
                var tableData = data.data.map(function (val) {
                    return val;
                });
                this.setState({
                    data: tableData
                });
            }
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    render() {
        return (
            <div>
                <BootstrapTable
                    keyField="id"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={true}
                    striped
                    hover
                    condensed
                    noDataIndication={this.emptyIndication}
                />
            </div>
        )
    }
}