import React, {Component} from "react";
import BeaconRepository from "../../services/BeaconRepository";
import BootstrapTable from "react-bootstrap-table-next";
import convertToDateTime from "../../helpers/convert";
import {Button} from "react-bootstrap";

export default class QRCodesTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: this.props.qrCodes,
            petId: this.props.petId
        }
        this.columns = [{
            dataField: 'id',
            text: 'Code'
        }, {
            dataField: 'type',
            text: 'Type'
        }, {
            dataField: 'firstLine',
            text: 'First Line'
        }, {
            dataField: 'secondLine',
            text: 'Second Line'
        }]

    }

    // downloadData = () => {
    //     this.setState({isLoading: true});
    //     var tagId = this.state.tagInfo.tagId.major + '.' + this.state.tagInfo.tagId.minor;
    //     BeaconRepository.getDevices(tagId).then(response => {
    //         if (!response.ok)
    //             throw Error(response.statusText);
    //         return response.json()
    //     }).then(data => {
    //         var tableData = data.data.map(function (val) {
    //             let lastTs = convertToDateTime(val.ts);
    //             return {
    //                 deviceId: val.deviceId,
    //                 deviceName: val.deviceName,
    //                 inRange: val.inRange
    //             }
    //         });
    //         this.setState({
    //             data: tableData
    //         });
    //     }).catch(error => {
    //         alert(error);
    //     });
    //     this.setState({isLoading: false});
    // }


    emptyIndication() {
        return "No data";
    }


    render() {
        return (
            <div>
                <BootstrapTable
                    keyField="deviceId"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={false}
                    striped
                    hover
                    condensed
                    noDataIndication={this.emptyIndication}/>
            </div>
        )
    }
}