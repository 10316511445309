import React, {Component} from "react";
import ReviewRepository from "../../services/ReviewRepository";
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

export default class UserReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId,
            data: []

        }
    }

    componentDidMount() {
        this.loadReviews();
    }

    loadReviews = () => {
        ReviewRepository.getUserReviews(this.props.userId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.setState({data: data.data})
        }).catch(error => {
            alert(error);
        });
    }

    printReviews = () => {
        if (this.state.data) {
            return (
                <div>
                    {
                        this.state.data.map
                        (
                            (position) =>
                                (<div>
                                        <br/>
                                        <Card>
                                            <Card.Body>
                                                <Container>
                                                    <Row className="show-grid">
                                                        <Col md={6}>
                                                            <p>Review asked date: <lable><b>{position.sendDate}</b></lable></p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <p>User action that
                                                                triggered: <lable><b>{position.reviewTrigger}</b></lable></p>
                                                        </Col>
                                                    </Row>

                                                    <Table responsive>
                                                        <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Answer</th>
                                                            <th>Platform</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {position.answers.map((answer) => (<tr>
                                                            <td>{answer.date}</td>
                                                            <td>{answer.answer}</td>
                                                            <td>{answer.platform}</td>
                                                        </tr>))}
                                                        </tbody>
                                                    </Table>
                                                </Container>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                        )
                    }
                </div>
            )
        }
    }

    render() {
        return (<div>{this.printReviews()}</div>)
    }
}