import React, {Component} from "react";
import {Button, Row, Col, Card} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import LoaderButton from "../components/LoaderButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import "./ReportTable.css";
import Form from 'react-bootstrap/Form'
import ReportRepository from "../services/ReportRepository";

export default class ReportTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: [],
            templates: [],
            newReportName: "",
            newReportTemplate: "",
            startDate: new Date(),
            endDate: new Date()
        };

        this.downloadButtonFormatter = this.downloadButtonFormatter.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);

        this.columns = [{
            dataField: 'id',
            text: 'Report',
            sort: true
        }, {
            dataField: 'templateName',
            text: 'Template'
        }, {
            dataField: 'name',
            text: 'Name'
        }, {
            dataField: 'from',
            text: 'From',
            sort: true
        }, {
            dataField: 'to',
            text: 'To'
        }, {
            dataField: 'createdBy',
            text: 'Created by'
        }, {
            dataField: 'createdDateString',
            text: 'Created date'
        }, {
            dataField: 'status',
            text: 'Status'
        }, {
            dataField: 'downloadButton',
            text: 'Download',
            formatter: this.downloadButtonFormatter
        }];
    }

    componentDidMount() {
        this.refreshTemplateList();
        this.handleRefresh();
    }

    refreshTemplateList() {
        ReportRepository.getTemplateList().then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            var templateData = data.data.map(function (val) {
                return {
                    value: val.id,
                    label: val.name,
                    name: val.name
                }
            });
            this.setState({
                templates: templateData
            });
            this.setState({
                newReportTemplate: templateData[0]
            })
        }).catch(error => {
            alert(error);
        });
    }

    handleAddNewReport = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        ReportRepository.addNewReport({
            templateId: this.state.newReportTemplate.value,
            params: {
                name: this.state.newReportName,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }
        }).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.handleRefresh()
        }).catch(error => {
            alert(error);
        });


        this.setState({isLoading: false});
    }

    validateForm() {
        return true;
    }

    handleRefresh = () => {
        this.setState({isLoading: true});
        ReportRepository.refresh().then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            var tableData = data.data.map(function (val) {
                return {
                    id: val.id,
                    templateName: val.templateName,
                    status: val.status,
                    name: val.name,
                    from: val.from,
                    to: val.to,
                    createdBy: val.createdBy,
                    createdDateString: val.createdDateString
                }
            });
            this.setState({
                data: tableData
            });
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    onDropdownChange = event => {
        this.setState({
            newReportTemplate: event
        })
    }

    handleStartDateChange(date) {
        this.setState({
            startDate: date
        })
    }

    handleEndDateChange(date) {
        this.setState({
            endDate: date
        })
    }

    handleReportDownload(row) {
        ReportRepository.reportDownload(row.id).then(response => {
            console.log(response.headers.get('Content-Disposition'))
            console.log(response.headers.get('Content-Type'))
            const filename = response.headers.get('Content-Disposition').split('filename=')[1].replace(/"/g, '');
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            })
        })
    }

    downloadButtonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm" onClick={() => this.handleReportDownload(row)}>Download data</Button>
    }

    render() {
        return (
            <div>
                <Card id="add-new-report-panel">
                    <Card.Header>Add new report</Card.Header>
                        <Card.Body>
                            <Form onSubmit={this.handleAddNewReport}>
                                <Form.Group as={Row} controlId="formHorizontalTemplate">
                                    <Form.Label column sm={2}>Template</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as={"select"} value={this.state.newReportTemplate}
                                                      onChange={this.onDropdownChange}>
                                            {
                                                this.state.templates.map((option, index) => {
                                                    return (<option key={index} value={option.value}>{option.name}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="newReportName">
                                    <Form.Label column sm={2}>Report name</Form.Label>
                                    <Col sm={10}>
                                    <Form.Control type={"text"} value={this.state.newReportName}
                                                  onChange={this.handleChange}
                                                  placeholder="Report name" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="startDate">
                                    <Form.Label column sm={2}>Start date</Form.Label>
                                    <Col sm={10}>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="endDate">
                                    <Form.Label column sm={2}>End date</Form.Label>
                                    <Col sm={10}>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col className="offset-md-4" sm={10}>
                                        <LoaderButton
                                            disabled={!this.validateForm()}
                                            type="submit"
                                            isLoading={this.state.isLoading}
                                            text="Add new report"
                                            loadingText="Creating new report…"
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>


                        </Card.Body>
                </Card>
                <br />
                <Button
                    onClick={() => this.handleRefresh()}>
                    Refresh
                </Button>
                <br />
                <BootstrapTable
                    keyField="id"
                    data={this.state.data}
                    columns={this.columns}
                    //remote={{ filter: true, pagination: true, sort: true}}
                    deleteRow={true}
                    striped
                    hover
                    condensed
                    defaultSorted={[{
                        dataField: 'id',
                        order: 'desc'
                    }]}
                    noDataIndication={this.emptyIndication
                    }
                />
            </div >
        )
    }
}