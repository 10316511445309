import userRequest from "./RequestService";

class WalkRepository {
    getPetWalks(petId) {
        return userRequest('/walk/api/support/pet/' + petId, {
            method: 'GET'
        })
    }
}

export default new WalkRepository();