import userRequest from "./RequestService";

class MobileRepository {
    getUserDevices(userId) {
        return userRequest('/mobile/api/support/devices/users/' + userId, {
            method: 'GET'
        });
    }
}

export default new MobileRepository();