import React, {Component} from "react";
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container"
import {Col, Row} from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Badge from "react-bootstrap/Badge";
import PetRelationTable from "./PetRelationTable";
import convertToDateTime from "../../helpers/convert";
import PetRepository from "../../services/PetRepository";
import PetWalkTable from "./PetWalkTable";
import PetProtectionTable from "./PetProtectionTable";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import QRCodesTable from "./QRCodesTable";

export default class PetViewSelected extends Component {
    constructor(props) {
        super(props);
        this.state = {
            petId: this.props.tab.petId,
            isLoading: false,
            petDetails: {
                tagInfo: {
                    tagId: {}
                },
                supportDetails: {
                    qrCodes: []
                }
            },
            tab: this.props.tab,
            deleteTagDialog: false
        }

        this.closeButton = this.props.closeButton.bind(this);
        this.handleAddSelectedUser = this.props.handleAddSelectedUser.bind(this)
        this.downloadData();
    }

    downloadData = () => {
        this.setState({isLoading: true});
        PetRepository.getPetDetails(this.state.petId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.setState({petDetails: data.value})
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        });
    }

    petBasic = () => {
        return (
            <div>
                <br/>
                <Container>
                    <Row className="show-grid">
                        <Col md={6}>
                            <p>Id: <label>{this.state.petDetails.id}</label></p>
                            <p>Name: <label>{this.state.petDetails.name}</label></p>
                            <p>Type: <label>{this.state.petDetails.petType}</label></p>
                            <p>Pet owner: <Button className="btn-sm"
                                                  onClick={() => this.handleAddSelectedUser(this.state.petDetails.supportDetails.petOwnerId)}>{this.state.petDetails.supportDetails.petOwnerId}</Button>
                            </p>
                        </Col>
                        <Col md={6}>
                            <p>User in
                                range: <label>{this.state.petDetails.userInRange ? ('Not implemented') : ('None')}</label>
                            </p>
                            <p>Walk: <label>{this.state.petDetails.onWalk ? ('Yes') : ('No')}</label></p>
                            <p>Virtual leash: <label>Not implemented</label></p>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={12}><p>Lost pet
                            post: <label>{this.state.petDetails.lostPostId ? ('https://app.pawscout.com/post/' + this.state.petDetails.lostPostId) : ('Not lost')}</label>
                        </p></Col>
                    </Row>
                </Container>
            </div>
        )
    }

    printTagHistory = () => {
        let supportDetails = this.state.petDetails.supportDetails;
        if(supportDetails && supportDetails.tagHistory) {
            let tbody = supportDetails.tagHistory.map((position) => (<tr><td>{position.createdDate}</td><td>{position.hardwareRevision}</td><td>{position.softwareRevision}</td></tr>))
            return (<div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Hardware</th>
                        <th>Firmware</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tbody}
                    </tbody>
                </Table>
            </div>)
        } else {
            return (<div><label>No tag history</label></div>)
        }
    }

    tagDetails = () => {
        var tagInfo = this.state.petDetails.tagInfo;
        var supportDetails = this.state.petDetails.supportDetails;
        var tagHistory = this.printTagHistory();
        if (tagInfo) {
            return (
                <div>
                    <br/>
                    <Container>
                        <Row className="show-grid">
                            <Col md={6}>
                                <p>Id: <label>{tagInfo.tagId.major}.{tagInfo.tagId.minor}</label></p>
                                <p>Hardware: <label>{tagInfo.hardwareRevision}</label></p>
                                <p>Software: <label>{tagInfo.softwareRevision}</label></p>
                                <p>Low battery: <label>{tagInfo.lowBattery ? ('Yes') : ('No')}</label></p>
                                <p>Store: <label>{supportDetails.tagStore} ({supportDetails.tagCountry})</label></p>
                            </Col>

                            {this.tagDetailsLocation(tagInfo.locationDetails)}

                        </Row>
                        {tagHistory}
                        <Row>
                            <Col md={{ size: 1, offset: 5 }}>
                                <Button variant="outline-danger" size="lg"
                                        onClick={() => this.setState({deleteTagDialog: true})}>Remove tag from {this.state.petDetails.name}</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        } else {
            return (
                <Container>
                    <Row>
                        <Col md={12} className={"text-center"}>
                            <br/>
                            <h3><Badge variant="secondary">No tag</Badge></h3>
                            <br/>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }


    tagDetailsLocation = location => {
        if (location) {
            return (
                <Col md={6}>
                    <p>Last location date: <label>{convertToDateTime(location.ts)}</label></p>
                    <p>Latitude: <label>{location.latitude}</label></p>
                    <p>Longitude: <label>{location.longitude}</label></p>
                </Col>
            )
        } else {
            return (
                <Col md={6}>
                    <p>No last location data</p>
                </Col>
            )
        }
    }

    printPet = () => {
        return (
            <Container>
                <Row><Col>
                    <Tabs defaultActiveKey="basic" transition={false} id="pet-tab">
                        <Tab eventKey="basic" title="Basic">
                            {this.petBasic()}
                        </Tab>
                        <Tab eventKey="tag" title="Tag Info">
                            {this.tagDetails()}
                        </Tab>
                        <Tab eventKey="qr" title="QR Codes">
                            {this.qrCodes()}
                        </Tab>
                        {/*<Tab eventKey={"protection"} title={"Protection"}>*/}
                        {/*    {this.petProtection()}*/}
                        {/*</Tab>*/}
                    </Tabs>
                </Col></Row>
            </Container>
        )
    }

    qrCodes = () => {
        let qrCodes = this.state.petDetails.supportDetails.qrs;
        if (qrCodes && Array.isArray(qrCodes) && qrCodes.length > 0) {
            return (
                <div>
                    <QRCodesTable petId={this.state.petId} qrCodes={qrCodes}/>
                </div>)
        } else {
            return (
                <Col md={6}>
                    <p>No QR Codes</p>
                </Col>
            )
        }
    }

    petProtection = () => {
        let petTagInfo = this.state.petDetails.tagInfo;
        if (petTagInfo && petTagInfo.tagId && petTagInfo.tagId.major) {
            return (
                <div>
                    <PetProtectionTable petId={this.state.petId} tagInfo={this.state.petDetails.tagInfo}/>
                </div>)
        } else {
            return (
                <Col md={6}>
                    <p>Pet protection enabled only for pets with tags</p>
                </Col>
            )
        }
    }

    handleCloseTagDialog = () => {
        this.setState({
            deleteTagDialog: false
        })
    }

    removeTagFromPet = () => {
        let tagId = this.state.petDetails.tagInfo.tagId.major + "." + this.state.petDetails.tagInfo.tagId.minor
        PetRepository.removeTagFromPet(this.state.petDetails.id, tagId).then(this.downloadData()).then(this.handleCloseTagDialog())
    }

    render() {
        return (
            <div>
                <br/>
                {this.printPet()}
                <br/>
                <Container>
                    <Row>
                        <Col>
                            <Tabs defaultActiveKey={"relations"} transition={false} id={"pet-details-tabs"}>
                                <Tab eventKey={"relations"} title={"Pet relations"}>
                                    <PetRelationTable petId={this.state.petId} handleAddSelectedUser={this.handleAddSelectedUser} reloadParent={this.downloadData}/>
                                </Tab>
                                {/*<Tab eventKey={"walks"} title={"Walks"}>*/}
                                {/*    <PetWalkTable petId={this.state.petId} handleAddSelectedUser={this.handleAddSelectedUser}/>*/}
                                {/*</Tab>*/}
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
                <br/>
                <Container>
                    <Row>
                        <Col>
                            <Button onClick={() => this.closeButton(this.state.tab)} variant="outline-danger" size="lg"
                                    block>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Container>

                <Modal style={{opacity: 1}} show={this.state.deleteTagDialog} onHide={this.handleCloseTagDialog}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove tag from pet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to remove {this.state.petDetails.tagInfo?.tagId.major}.{this.state.petDetails.tagInfo?.tagId.minor} from pet {this.state.petDetails.name}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-danger" size="lg" onClick={this.removeTagFromPet}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={this.handleCloseTagDialog}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}