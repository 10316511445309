import React, {Component} from "react";
import Button from "react-bootstrap/Button"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import UserTable from "../UserTable";
import UserSelected from "../UserSelected";
import PetViewTable from "../pet/PetViewTable";
import PetViewSelected from "../pet/PetViewSelected";

export default class SupportView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nextId: 1,
            tabs: [],
            clientId: null
        };
    }

    handleRemoveTab = (tab) => {
        const {tabs} = this.state;

        var newTabs = tabs.filter(function (_tab) {
            return _tab.id !== tab.id
        });

        this.setState({
            tabs: newTabs
        });
    }

    handleAddSelectedUser = (userId) => {
        if (userId == null)
            return;
        const {tabs, nextId} = this.state;

        const newTabObject = {
            id: nextId,
            name: `User ${userId}`,
            userId: userId,
            petId: null,
            content: `User ${userId}`
        };

        this.setState({
            tabs: [...tabs, newTabObject],
            nextId: nextId + 1
        });
    };

    handleAddSelectedPet = (petId) => {
        if (petId == null)
            return;
        const {tabs, nextId} = this.state;

        const newTabObject = {
            id: nextId,
            name: `Pet ${petId}`,
            userId: null,
            petId: petId,
            content: `Pet ${petId}`
        };

        this.setState({
            tabs: [...tabs, newTabObject],
            nextId: nextId + 1
        });
    };

    createTabs = () => {
        const {tabs} = this.state;

        if (tabs.length == 0)
            return <div></div>;


        const allTabs = tabs.map(tab => {
            return this.renderTab(tab)
        });

        return <Tabs id="test" transition={false}>{allTabs}</Tabs>;
    };

    renderTab = (tab) => {
        if (tab.userId != null) {
            return (
                <Tab eventKey={tab.id} title={tab.name} key={tab.id} aria-selected="true">
                    <UserSelected tab={tab} clientId={tab.userId} closeButton={this.handleRemoveTab}
                                  openPet={this.handleAddSelectedPet}/>
                </Tab>
            )
        } else if (tab.petId != null) {
            return (
                <Tab eventKey={tab.id} title={tab.name} key={tab.id} aria-selected="true">
                    <div>
                        <PetViewSelected tab={tab} petId={tab.userId} closeButton={this.handleRemoveTab}
                                         handleAddSelectedUser={this.handleAddSelectedUser} />
                    </div>
                </Tab>
            )
        } else {
            return (
                <Tab eventKey={tab.id} title={tab.name} key={tab.id} aria-selected="true">
                    <div>
                        Unknown
                        <Button onClick={() => this.handleRemoveTab(tab)}>
                            <i className="fa fa-window-close"></i>
                        </Button>
                    </div>
                </Tab>
            )
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Tabs defaultActiveKey="user" transition={false} id="search-tab">
                        <Tab eventKey="user" title="User">
                            <UserTable setSelectedUser={this.handleAddSelectedUser}/>
                        </Tab>
                        <Tab eventKey="pet" title="Pet">
                            <PetViewTable setSelectedPetId={this.handleAddSelectedPet}/>
                        </Tab>
                    </Tabs>
                </div>
                <br/>
                <div>
                    {this.createTabs()}
                </div>
            </div>
        );
    }
}