import userAuthHeader from "./auth-header";
import AuthService from "./auth.service";

const API_URL = process.env.REACT_APP_BASE_PATH;

export default async function userRequest(uri, options) {
    options.headers = userAuthHeader();
    // let response = await fetch(API_URL + uri, options)

    return fetch(API_URL + uri, options)
        .then(response => {
            if(response.ok) {
                return response;
            }
            if (response.status == 401) {
                return AuthService.refresh().then(response => {
                    options.headers = userAuthHeader();
                    return fetch(API_URL + uri, options).then(val => {
                        return val;
                    });
                })
            } else if (response.status == 403) {
                throw new Error("You are not authorized to do this action")
            } else {
                return response;
            }
        })
    // let response = await fetch(API_URL + uri, options)
    //     .then(res => {
    //         if(res.ok) {
    //             return res;
    //         }
    //         throw new Error(res.status);
    //     }, onerror => {
    //         console.log(onerror);
    //     })
    //     .catch(error => {
    //         console.log(error);
    //     });
    // if (response && response.ok) {
    //     return response;
    // }


}
