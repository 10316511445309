import userRequest from "./RequestService";

class ImageRepository {
    imageSearch(imageId) {
        return userRequest('/statistic/api/support/image/' + imageId, {
            method: 'GET'});
    }

    getImage(imageId) {
        return userRequest('/resource/api/support/image/id/' + imageId, {
            method: 'GET'});
    }
}

export default new ImageRepository();