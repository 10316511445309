import React, {Component} from "react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/button';
import {Col, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";

export default class NewProcessingModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type: "engraving", maxDate: null
        }

        this.handleMaxDateChange = this.handleMaxDateChange.bind(this);
        this.isAllDate = this.isAllDate.bind(this);
    }


    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleMaxDateChange(date) {
        this.setState({
            maxDate: date
        })
    }

    isAllDate = () => {
        return this.state.type !== null && this.state.maxDate !== null;
    }

    render() {
        return (<Modal
            style={{opacity: 1}}
            show={this.props.isOpen}
            onHide={this.props.closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Run Shopify script</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} controlId="maxDate">
                    <Form.Label column sm={6}>Max date (PST start of day)</Form.Label>
                    <Col sm={6}>
                        <DatePicker
                            selected={this.state.maxDate}
                            onChange={this.handleMaxDateChange}
                            dateFormat="yyyy-MM-dd"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="type">
                    <Form.Label column sm={6}>Type: </Form.Label>
                    <Col sm={6}>
                        <Form.Control as="select" onChange={this.handleChange}>
                            <option value="engraving">Engraving</option>
                            <option value="tag">Tag</option>
                            <option value="battery">Battery</option>
                            <option value="all">All</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" size="lg"
                        disabled={!this.isAllDate()}
                        onClick={() => this.props.handleSubmit(this.state.type, this.state.maxDate)}>
                    Run script
                </Button>
            </Modal.Footer>
        </Modal>)
    }
}