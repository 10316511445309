import React, {Component} from "react";
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/button'

export default class ModalForm extends Component {

    state={ tagId: null, name: null }

    // handleChange = (e) => this.setState({name: e.target.value})
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    render(){
        return(
            <Modal
                style={{opacity: 1}}
                show={this.props.isOpen}
                onHide={this.props.closeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Register Device</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="tagId">
                        <Form.Label>Tag ID: </Form.Label>
                        <Form.Control type="text" onChange={this.handleChange} value={this.state.tagId} placeholder="tag id"/>
                    </Form.Group>
                    <Form.Group controlId="name">
                        <Form.Label>Device Name: </Form.Label>
                        <Form.Control type="text" onChange={this.handleChange} value={this.state.name} placeholder="device name"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={() => this.props.handleSubmit(this.state.tagId, this.state.name)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}