import React, {Component} from "react";
import BeaconRepository from "../../services/BeaconRepository";
import BootstrapTable from "react-bootstrap-table-next";
import convertToDateTime from "../../helpers/convert";
import {Button} from "react-bootstrap";

export default class PetProtectionTable extends Component {

    constructor(props) {
        super(props);
        this.removeDeviceFormatter = this.removeDeviceFormatter.bind(this);
        this.state = {
            isLoading: false,
            data: [],
            petId: this.props.petId,
            tagInfo: this.props.tagInfo
        }
        this.columns = [{
            dataField: 'deviceId',
            text: 'Device ID'
        }, {
            dataField: 'deviceName',
            text: 'Device Name'
        }, {
            dataField: 'inRange',
            text: 'Is in range'
        }, {
            dataField: 'button',
            text: 'Actions',
            formatter: this.removeDeviceFormatter
        }]

        if (this.state.tagInfo) {
            this.downloadData();
        }
    }

    downloadData = () => {
        this.setState({isLoading: true});
        var tagId = this.state.tagInfo.tagId.major + '.' + this.state.tagInfo.tagId.minor;
        BeaconRepository.getDevices(tagId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            var tableData = data.data.map(function (val) {
                let lastTs = convertToDateTime(val.ts);
                return {
                    deviceId: val.deviceId,
                    deviceName: val.deviceName,
                    inRange: val.inRange
                }
            });
            this.setState({
                data: tableData
            });
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    removeDeviceFormatter(cell, row, rowIndex, formatExtraData) {
        return <div>
            <Button variant="outline-danger" size="lg"
                    onClick={() => this.removeDevice(row.deviceId)}>Remove device</Button>
        </div>
    }

    removeDevice = (deviceId) => {
        console.log(deviceId)
        console.log(tagId)
        var tagId = this.state.tagInfo.tagId.major + '.' + this.state.tagInfo.tagId.minor;
        BeaconRepository.deregisterDevice(tagId, deviceId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.downloadData()
        }).catch(error => {
            alert(error);
            this.handleClose();
        })

    }

    emptyIndication() {
        return "No data";
    }


    render() {
        return (
            <div>
                <BootstrapTable
                    keyField="deviceId"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={false}
                    striped
                    hover
                    condensed
                    noDataIndication={this.emptyIndication}/>
            </div>
        )
    }
}