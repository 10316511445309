import userRequest from "./RequestService";

class BeaconRepository {

    getDevices(tagId) {
        return userRequest('/beacon/api/v1/guard/'+tagId, {
            method: 'GET'
        })
    }

    registerDevice(tagId, deviceId, name) {
        return userRequest('/beacon/api/v1/guard/'+tagId+'/'+deviceId, {
            method: 'POST',
            body: JSON.stringify({
                deviceName: name
            })
        })
    }

    deregisterDevice(tagId, deviceId) {
        return userRequest('/beacon/api/v1/guard/'+tagId+'/'+deviceId, {
            method: 'DELETE'
        })
    }
}

export default new BeaconRepository();