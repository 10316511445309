import React, {Component} from "react";
import Form from 'react-bootstrap/Form'
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import AuthService from "../services/auth.service";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            user: "",
            password: ""
        };
    }

    validateForm() {
        return this.state.user.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({ isLoading: true });
        AuthService.login(this.state.user, this.state.password).then(data => {
            this.props.userHasAuthenticated(true);
            this.setState({ isLoading: false });
            this.props.history.push("/support");
        }).catch(error => {
            this.setState({ isLoading: false });
            alert(error);
        });
    }

    render() {
        return (
            <div className="Login">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="user">
                        <Form.Label>User</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={this.state.user} onChange={this.handleChange.bind(this)} />
                    </Form.Group>

                    <Form.Group controlId={"password"}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type={"password"}  placeholder="Password" onChange={this.handleChange.bind(this)} />
                    </Form.Group>

                    <LoaderButton
                        block
                        className="btn-lg"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Login"
                        loadingText="Logging in…"
                    />
                </Form>
            </div>
        );
    }
}