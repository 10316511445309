import convertToDateTime from "./convert";
import Card from "react-bootstrap/Card";
import React from "react";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

export default function postMapper(val) {
    if (val.type == 'POST') {
        return {
            id: val.id,
            user: val.post.user,
            created: convertToDateTime(val.post.createdTimestamp),
            updated: convertToDateTime(val.post.updatedTimestamp),
            type: val.post.postType,
            message: getMessage(val.post),
            images: val.post.gallery,
            petTag: val.post.petTag,
            location: 'not implemented',
            sharelink: val.post.metadata.shareUrl,
            likes: val.post.metadata.likes,
            comments: val.post.metadata.comments
        };
    } else {
        return {};
    }
}

export function commentMapper(val) {
    return {
        id: val.id,
        user: val.user,
        created: convertToDateTime(val.createdTimestamp),
        message: getMessage(val),
        images: val.image
    };
}

export function getMessage(val) {
    if (val.message) {
        return val.message.message;
    }
    return '';
}

export function getMessageCard(row) {
    if(row.message) {
        return (
            <Card>
                <Card.Header>Message</Card.Header>
                <Card.Body>
                    <Card.Text>{row.message}</Card.Text>
                </Card.Body>
            </Card>
        )
    }
}

export function getImagesCard(row) {
    if(row.images) {
        let gallery = row.images.images.map((item, key) =>
            <Col><Image src={'https://api.pawscout.com/resource/images/' + item.id + '?size=200&type=WIDTH'} rounded/></Col>)
        return (
            <Card>
                <Card.Header>Images</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <Container>
                            <Row>
                                {gallery}
                            </Row>
                        </Container>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }
}

export function getVideoCard(row) {

}


