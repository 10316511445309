import React, {Component} from "react";
import {Button} from "react-bootstrap";
import WalkRepository from "../../services/WalkRepository";
import BootstrapTable from "react-bootstrap-table-next";

export default class PetWalkTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            walks: [],
            petId: this.props.petId
        }

        this.goToUserButtonFormatter = this.goToUserButtonFormatter.bind(this);
        this.walkColumns = [{
            dataField: 'id',
            text: 'Walk ID'
        }, {
            dataField: 'userId',
            text: 'User',
            formatter: this.goToUserButtonFormatter
        }, {
            dataField: 'distance',
            text: 'Distance'
        }, {
            dataField: 'duration',
            text: 'Duration'
        }, {
            dataField: 'mapImageId',
            text: 'Map'
        }, {
            dataField: 'shareImageId',
            text: 'Share image'
        }, {
            dataField: 'status',
            text: 'Status'
        }]
    }

    componentDidMount() {
        this.downloadData();
    }


    goToUserButtonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm"
                       onClick={() => this.handleAddSelectedUser(row.userId)}>{row.userId}</Button>
    }

    downloadData = () => {
        this.setState({isLoading: true});
        let utils = this;
        WalkRepository.getPetWalks(this.state.petId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.setState({walks: data})
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    render() {
        return (
            <div>
                <BootstrapTable
                    keyField="id"
                    data={ this.state.walks }
                    columns={ this.walkColumns }
                    remote={ true }
                    deleteRow={ true }
                    striped
                    hover
                    condensed
                    noDataIndication={ this.emptyIndication }
                />
            </div>

        );
    }
}