import React, {Component} from "react";
import {FormGroup, FormControl, ControlLabel, Form, Button} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "./PetTable.css";
import PetRepository from "../services/PetRepository";
import convertToDateTime from "../helpers/convert";

export default class PetTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            userEmail: "",
            data: [],
            userClient: null
        };

        this.userId = this.props.userId;
        this.openPet = this.props.openPet.bind(this)
        this.goToPetButtonFormatter = this.goToPetButtonFormatter.bind(this);
        //was const columns!

        this.columns = [{
            dataField: 'id',
            text: 'Pet ID',
            formatter: this.goToPetButtonFormatter
        }, {
            dataField: 'name',
            text: 'Pet name'
        }, {
            dataField: 'petType',
            text: 'Pet type'
        }, {
            dataField: 'relationType',
            text: 'Relation type'
        }, {
            dataField: 'tagId',
            text: 'Tag id'
        }, {
            dataField: 'lost',
            text: 'Is lost'
        }, {
            dataField: 'onWalk',
            text: 'Is on walk'
        }, {
            dataField: 'lastKnownLocation',
            text: 'Last known location'
        }, {
            dataField: 'detectedDetails',
            text: 'Last detected details'
        }];
    }

    componentDidMount() {
        this.loadPets();
    }

    goToPetButtonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm" onClick={() => this.openPet(row.id)}>{row.id}</Button>
    }

    emptyIndication() {
        return "No data";
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    loadPets = () => {
        this.setState({isLoading: true});
        let util = this;
        PetRepository.findUserPets(util.userId)
        .then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            if (typeof data.data !== 'undefined' && data.data.length > 0) {
                var tableData = data.data.map(function (val) {
                    let tag = util.getTagInfo(val);
                    let location = util.getLastKnownLocation(val.tagInfo);
                    let detected = util.getDetectedDetails(val.detectedDetails);
                    return {
                        id: val.id,
                        lost: val.lost,
                        name: val.name,
                        onWalk: val.onWalk,
                        petType: val.petType,
                        relationType: val.relationType,
                        tagId: tag,
                        lastKnownLocation: location,
                        detectedDetails: detected
                    }
                });
                this.setState({
                    data: tableData
                });
            } else {
                this.setState({
                    data: []
                });
            }
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    getTagInfo(val) {
        let tagInfo = val.tagInfo;
        if (tagInfo != null && typeof tagInfo !== 'undefined') {
            return tagInfo.tagId.major + '.' + tagInfo.tagId.minor + ' (hw: ' + tagInfo.hardwareRevision + ', fw: ' + tagInfo.softwareRevision + '), paired: '+val.tagPaired;
        }

        return 'Not tag';
    }

    getLastKnownLocation(tagInfo) {
        if (tagInfo != null && typeof tagInfo !== 'undefined') {
            if (tagInfo.locationDetails != null && tagInfo.locationDetails !== 'undefined') {
                let location = 'Latitude: ' + tagInfo.locationDetails.latitude + ", longitude: " + tagInfo.locationDetails.longitude + ", accuracy: " + tagInfo.locationDetails.accuracy + ", date: " + convertToDateTime(tagInfo.locationDetails.ts);
                return location;
            }
        }

        return 'Location was never send';
    }

    getDetectedDetails(detectedDetails) {
        if (detectedDetails != null && typeof detectedDetails !== 'undefined') {
            let details = 'Detected by: ' + detectedDetails.lastDetectedBy.name + ', low battery:' + detectedDetails.lowBattery;
            return details;
        }

        return "No last tag detected details";
    }

    render() {
        return (
            <div>
                <BootstrapTable
                    keyField="id"
                    data={ this.state.data }
                    columns={ this.columns }
                    remote={ true }
                    deleteRow={ true }
                    striped
                    hover
                    condensed
                    noDataIndication={ this.emptyIndication }
                />
            </div>

        );
    }
}