import React, { Component } from "react";
import {Link} from "react-router-dom";
import "./Home.css";

export default class Home extends Component {
    render() {
        return (
            <div className="Home">
                <div className="lander">
                    <h1>Support Portal</h1>
                </div>
            </div>
        );
    }
}