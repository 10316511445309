import React, {Component} from "react";
import {Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import BootstrapTable from "react-bootstrap-table-next";
import "./UserTable.css";
import UserRepository from "../services/UserRepository";

export default class UserTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            userEmail: "",
            data: [],
            userClient: null,
            numberOfFoundUsers: "",
            userLastName: "",
            userId: ""
        };

        this.buttonFormatter = this.buttonFormatter.bind(this);
        this.setSelectedUser = this.props.setSelectedUser.bind(this);
        //was const columns!
        this.columns = [{
            dataField: 'id', text: 'User ID'
        }, {
            dataField: 'email', text: 'Email'
        }, {
            dataField: 'type', text: 'Account type'
        }, {
            dataField: 'name', text: 'Name'
        }, {
            dataField: 'button', text: 'Actions', formatter: this.buttonFormatter
        }];
    }

    emptyIndication() {
        return "No data";
    }

    validateForm() {
        return this.state.userEmail.length > 0;
    }

    validateFormLastName() {
        return this.state.userLastName.length > 0;
    }

    validateFormUserId() {
        return this.state.userId.length > 0;
    }

    clearSelection = () => {
        this.setSelectedUser(null);
        this.setState({
            data: []
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleUserSearch = event => {
        event.preventDefault();
        UserRepository.findUsers(this.state.userEmail, null, null)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json()
            }).then(data => {
            this.setState({
                numberOfFoundUsers: "Found " + data.data.length + " users for email: " + this.state.userEmail
            });
            if (data.data.length == 0) {
                this.setState({emptyMessage: "for: " + this.state.userEmail});
            }
            var tableData = data.data.map(function (val) {
                return {
                    id: val.userId,
                    email: val.email,
                    type: (val.authentication.emailAccess == true ? "EMAIL, " : "") + (val.authentication.facebookAccess == true ? "FACEBOOK, " : "") + (val.authentication.appleAccess == true ? "APPLE, " : ""),
                    name: val.firstName + " " + val.middleName + " " + val.lastName
                }
            });
            this.setState({
                data: tableData,
                isLoading: false
            });
            this.setSelectedUser(null);
        }).catch(error => {
            alert(error);
        });
    }

    handleUserSearchByLastName = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        UserRepository.findUsers(null, this.state.userLastName, null)
            .then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response.json()
            }).then(data => {
            this.setState({
                numberOfFoundUsers: "Found " + data.data.length + " users for last name: " + this.state.userLastName
            });
            if (data.data.length == 0) {
                this.setState({emptyMessage: "for: " + this.state.userEmail});
            }
            var tableData = data.data.map(function (val) {
                return {
                    id: val.userId,
                    email: val.email,
                    type: val.authenticationType,
                    name: val.firstName + " " + val.middleName + " " + val.lastName
                }
            });
            this.setState({
                data: tableData
            });
            this.setSelectedUser(null);
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    handleUserSearchById = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        UserRepository.findUsers(null, null, this.state.userId)
            .then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response.json()
            }).then(data => {
            this.setState({
                numberOfFoundUsers: "Found " + data.data.length + " users for last name: " + this.state.userLastName
            });
            if (data.data.length == 0) {
                this.setState({emptyMessage: "for: " + this.state.userEmail});
            }
            var tableData = data.data.map(function (val) {
                return {
                    id: val.userId,
                    email: val.email,
                    type: val.authenticationType,
                    name: val.firstName + " " + val.middleName + " " + val.lastName
                }
            });
            this.setState({
                data: tableData
            });
            this.setSelectedUser(null);
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    handleUserSelected(row) {
        this.setSelectedUser(row.id);
    }

    buttonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm" onClick={() => this.handleUserSelected(row)}>Show data</Button>
    }

    render() {
        return (<div>
                <Container>
                    <Row className="show-grid">
                        <Col md={6}>
                            <Form inline onSubmit={this.handleUserSearch}>
                                <FormGroup controlId="userEmail">
                                    <FormLabel>Find user by email</FormLabel>{' '}
                                    <FormControl
                                        autoFocus
                                        type="email"
                                        value={this.state.userEmail}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>{' '}
                                <LoaderButton
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Search"
                                    loadingText="Searching…"
                                />
                            </Form>
                            <Form inline onSubmit={this.handleUserSearchById}>
                                <FormGroup controlId="userId">
                                    <FormLabel>Find user by id</FormLabel>{' '}
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        value={this.state.userId}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>{' '}
                                <LoaderButton
                                    disabled={!this.validateFormUserId()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Search"
                                    loadingText="Searching…"
                                />
                            </Form>
                        </Col>
                        <Col md={6}>
                            <Form inline onSubmit={this.handleUserSearchByLastName}>
                                <FormGroup controlId="userLastName">
                                    <FormLabel>Find user by last name</FormLabel>{' '}
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        value={this.state.userLastName}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>{' '}
                                <LoaderButton
                                    disabled={!this.validateFormLastName()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Search"
                                    loadingText="Searching…"
                                />
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>

                        </Col>
                    </Row>
                </Container>
                <label>{this.state.numberOfFoundUsers}</label>
                <br/>
                <Button className="btn-sm" onClick={() => this.clearSelection()}>Clear selection</Button>
                <br/>
                <BootstrapTable
                    keyField="id"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={true}
                    striped
                    hover
                    condensed
                    noDataIndication={this.emptyIndication}
                />
            </div>

        );
    }
}