import React, {Component} from "react";
import ImageView from "./ImageView"

export default class Image extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageUrl: null
        }
    }

    setImageUrl = (url) => {
        this.setState({imageUrl: url})
    }

    render() {
        return (
            <div>
                <ImageView basePath={this.props.basePath} accessToken={this.props.accessToken}
                          />
            </div>
        )
    }
}
