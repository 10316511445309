import React, {Component, Fragment} from "react";
import {Link, withRouter} from "react-router-dom";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {LinkContainer} from "react-router-bootstrap";
import "./App.css";
import Routes from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import AuthService from "./services/auth.service";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            accessToken: "",
            refreshToken: "",
            basePath: process.env.REACT_APP_BASE_PATH,
            appAccessToken: process.env.REACT_APP_APP_ACCESS_TOKEN
        };
    }

    componentDidMount() {
        var isAuthenticated = localStorage.getItem('isAuthenticated');
        if (isAuthenticated === "true") {
            this.userHasAuthenticated(localStorage.getItem('isAuthenticated'));
        }
    }

    userHasAuthenticated = authenticated => {
        localStorage.setItem('isAuthenticated', authenticated);
        this.setState({isAuthenticated: authenticated});
    }


    handleLogout = event => {
        AuthService.logout();
        this.setState({isAuthenticated: false});
        this.props.history.push("/login");
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            accessToken: this.state.accessToken,
            refreshToken: this.state.refreshToken,
            basePath: this.state.basePath,
            appAccessToken: this.state.appAccessToken,

            userHasAuthenticated: this.userHasAuthenticated,
            setUserAccessToken: this.setUserAccessToken,
            setUserRefreshToken: this.setUserRefreshToken,
        }
        return (
            <Container>


                <Navbar bg={'light'} expand={'lg'}>
                    <Navbar.Brand>
                        {this.state.isAuthenticated
                            ? <Link to="/support" className={"nav-link"}>Support</Link>
                            : ""
                        }
                    </Navbar.Brand>
                    <Navbar.Brand>
                        {this.state.isAuthenticated
                            ? <Link to="/feed" className={"nav-link"}>Feed</Link>
                            : ""
                        }
                    </Navbar.Brand>
                    <Navbar.Brand>
                        {this.state.isAuthenticated
                            ? <Link to="/image" className={"nav-link"}>Images</Link>
                            : ""
                        }
                    </Navbar.Brand>
                    <Navbar.Brand>
                        {this.state.isAuthenticated
                            ? <Link to="/report" className={"nav-link"}>Reports</Link>
                            : ""
                        }
                    </Navbar.Brand>
                    <Navbar.Brand>
                        {this.state.isAuthenticated
                            ? <Link to="/order" className={"nav-link"}>Orders</Link>
                            : ""
                        }
                    </Navbar.Brand>
                    <Navbar.Brand>
                        {this.state.isAuthenticated
                            ? <Link to="/backend" className={"nav-link"}>Backend status</Link>
                            : ""
                        }
                    </Navbar.Brand>
                    <Navbar.Brand>
                        {this.state.isAuthenticated
                            ? <Link to="/statistics" className={"nav-link"}>Statistics</Link>
                            : ""
                        }
                    </Navbar.Brand>
                    <Navbar.Brand>
                        {this.state.isAuthenticated
                            ? <Link to="/removedUsers" className={"nav-link"}>Removed Users</Link>
                            : ""
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle/>

                    <Navbar.Collapse>
                        <Nav>
                            {this.state.isAuthenticated
                                ? <NavItem onClick={this.handleLogout}>Logout</NavItem>
                                : <Fragment>
                                    <LinkContainer to="/login">
                                        <NavItem>Login</NavItem>
                                    </LinkContainer>
                                </Fragment>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Routes childProps={childProps}/>
            </Container>
        );
    }
}

export default withRouter(App);