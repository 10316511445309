export default function userAuthHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if(user && user.token && user.token.accessToken) {
        return new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + user.token.accessToken
        })
    } else {
        console.log("Empty headers");
        return appAuthHeader();
    }
}

export function appAuthHeader() {
    return new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + process.env.REACT_APP_APP_ACCESS_TOKEN
    })
}
