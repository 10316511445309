import React, {Component} from "react";
import UserRepository from "../../services/UserRepository";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class UserAuthentications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId,
            data: [],
            showConfirmationModal: false,
            url: "",
            emailChangeDialog: false,
            newEmail: null,
            oldEmail: null,
            addEmailDialog: false,
            newUserEmail : null
        }
    }

    componentDidMount() {
        this.loadAuthentications();
    }

    loadAuthentications = () => {
        UserRepository.getUserAuthentications(this.props.userId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.setState({data: data.data})
        }).catch(error => {
            alert(error);
        });
    }

    printActions = (auth) => {
        if (auth.authenticationType === 'EMAIL') {
            return (<div>
                    <Button onClick={() => UserRepository.resetUserPassword(this.state.userId, auth.id)
                        .then(response => {
                            if (!response.ok)
                                throw Error(response.statusText);
                            return response.json()
                        }).then(data => {
                            this.setState({url: data.value, showConfirmationModal: true})
                        }).catch(error => {
                            alert(error);
                        })}>Reset password</Button>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }

    printEmailChange = (auth) => {
        if (auth.authenticationType === 'EMAIL') {
            return (<div>
                    <Button onClick={() => this.setState({emailChangeDialog: true, oldEmail: auth.id})}>Change user
                        email</Button>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }

    addUserEmail = () => {
        if (this.state.newUserEmail) {
            UserRepository.addUserEmail(this.state.userId, this.state.newUserEmail)
                .then(response => {
                    return response.json();
                }).then(resetResponse => {
                    if (resetResponse.value) {
                        this.loadAuthentications()
                        let message = 'Reset password link for new email: ' + resetResponse.value;
                        alert(message);
                    } else {
                        throw Error(resetResponse.message);
                    }
            }).catch(error => {
                alert(error);
            }).finally(() => this.handleCloseNewEmail());
        }
    }

    passwordResetConfirmationModal = () => {
        return (
            <Modal style={{opacity: 1}} show={this.state.showConfirmationModal}
                   onHide={() => this.setState({showConfirmationModal: false, url: null})}>
                <Modal.Header closeButton>
                    <Modal.Title>Password reset link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>{this.state.url}</div>
                </Modal.Body>
            </Modal>
        )
    }
    addUserEmailAuthenticationModal = () => {
        return (
            <Modal style={{opacity: 1}} show={this.state.addEmailDialog}
                   onHide={this.handleCloseNewEmail}>
                <Modal.Header closeButton>
                    <Modal.Title>Add user authentication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                        <p>Add new authentication email?</p>
                        <input type="email" id={"newUserEmail"} placeholder={"New email"}
                               onChange={this.handleChange} style={{width: "400px"}} autoComplete="off"/>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Container className="d-flex justify-content-between">
                        <Row>
                            <Col><Button size="lg" onClick={this.addUserEmail}>
                                ADD
                            </Button></Col>
                            <Col><Button variant="primary" size="lg" onClick={this.handleCloseNewEmail}>
                                CANCEL
                            </Button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        )
    }

    handleCloseNewEmail = () => {
        this.setState({
                addEmailDialog: false,
                newUserEmail: null
            }
        )
    }

    printAuthentications = () => {
        if (this.state.data) {
            return (
                <div>
                    <br/>
                    <Table responsive striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Type</th>
                            <th>ID</th>
                            <th>Password</th>
                            {/*<th>Email change</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map(
                            (position) => (
                                <tr>
                                    <td>{position.authenticationType}</td>
                                    <td>{position.id}</td>
                                    <td>{this.printActions(position)}</td>
                                    {/*<td>{this.printEmailChange(position)}</td>*/}
                                </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </div>
            )
        }
        return (<div><label>Data loading..</label></div>)
    }

    addUserEmailAuthentication = () => {
        return (
            <div>
               <Button onClick={() => this.setState({
                   addEmailDialog : true
               })}>
                   Add user email
               </Button>
            </div>
        )
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    changeUserEmail = () => {
        if (this.state.newEmail) {
            UserRepository.changeEmail(this.state.userId, this.state.newEmail)
                .then(response => {
                    if (!response.ok)
                        throw Error(response.statusText);
                    return response.json()
                }).then(response => {
                let message = 'Changed email to: ' + response.value.email;
                alert(message);
            }).catch(error => {
                alert(error);
            })
                .finally(() => this.handleClose())
        }
    }

    handleClose = () => {
        this.setState({emailChangeDialog: false, oldEmail: null});
    }

    render() {
        return (
            <div>
                {this.printAuthentications()}
                {this.addUserEmailAuthentication()}
                {this.passwordResetConfirmationModal()}
                {this.addUserEmailAuthenticationModal()}
                {/*<Modal style={{opacity: 1}} show={this.state.emailChangeDialog} onHide={this.handleClose}>*/}
                {/*    <Modal.Header closeButton>*/}
                {/*        <Modal.Title>Change user email?</Modal.Title>*/}
                {/*    </Modal.Header>*/}
                {/*    <Modal.Body>*/}
                {/*        <div className={"text-center"}>*/}
                {/*            <p>Are you sure you want to change user email ({this.state.oldEmail})?<br/>*/}
                {/*                <b>Changing user email will require user to use new one when logging to the app.</b></p>*/}
                {/*            <input type="email" id={"newEmail"} placeholder={"New email"}*/}
                {/*                   onChange={this.handleChange} style={{width: "400px"}} autoComplete="off"/>*/}
                {/*        </div>*/}
                {/*    </Modal.Body>*/}
                {/*    <Modal.Footer>*/}
                {/*        <Container className="d-flex justify-content-between">*/}
                {/*            <Row>*/}
                {/*                <Col><Button variant="outline-danger" size="lg"*/}
                {/*                             onClick={this.changeUserEmail}>*/}
                {/*                    CHANGE*/}
                {/*                </Button></Col>*/}
                {/*                <Col><Button variant="primary" size="lg" onClick={this.handleClose}>*/}
                {/*                    CANCEL*/}
                {/*                </Button></Col>*/}
                {/*            </Row>*/}
                {/*        </Container>*/}
                {/*    </Modal.Footer>*/}
                {/*</Modal>*/}
            </div>
        )
    }
}