import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Col, Form, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import PetRepository from "../../services/PetRepository";

export default class PetViewTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            petId: "",
            data: [],
            tagId: ""
        }
        this.buttonFormatter = this.buttonFormatter.bind(this);
        this.setSelectedPetId = this.props.setSelectedPetId.bind(this);
        this.columns = [{
            dataField: 'id',
            text: 'Pet id'
        }, {
            dataField: 'name',
            text: 'Name'
        }, {
            dataField: 'button',
            text: 'Actions',
            formatter: this.buttonFormatter
        }]
    }

    validateForm() {
        return this.state.petId.length > 0;
    }

    validateFormTagId() {
        return this.state.tagId.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    buttonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm" onClick={() => this.handlePetSelected(row)}>Show data</Button>
    }

    handlePetSelected(row) {
        this.setSelectedPetId(row.id);
    }

    emptyIndication() {
        return "No data";
    }

    handlePetSearchByTagId = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        PetRepository.findPetByTagId(this.state.tagId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            if (data.data.length == 0) {
                this.setState({emptyMessage: "No results"});
            }
            var tableData = data.data.map(function (val) {
                return {
                    id: val.petId,
                    name: val.name
                }
            });
            this.setState({
                data: tableData
            });
            this.setSelectedPetId(null);
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    handlePetSearchById = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        PetRepository.findPet(this.state.petId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            if (data.data.length == 0) {
                this.setState({emptyMessage: "No results"});
            }
            var tableData = data.data.map(function (val) {
                return {
                    id: val.petId,
                    name: val.name
                }
            });
            this.setState({
                data: tableData
            });
            this.setSelectedPetId(null);
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    render() {
        return (
            <div>
                <Container>
                    <Row className="show-grid">
                        <Col md={6}>
                            <Form inline onSubmit={this.handlePetSearchById}>
                                <FormGroup controlId="petId">
                                    <FormLabel>Find pet by id</FormLabel>{' '}
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        value={this.state.petId}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>{' '}
                                <LoaderButton
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Search"
                                    loadingText="Searching…"
                                />
                            </Form>
                        </Col>
                        <Col md={6}>
                            <Form inline onSubmit={this.handlePetSearchByTagId}>
                                <FormGroup controlId="tagId">
                                    <FormLabel>Find pet by tag id</FormLabel>{' '}
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        value={this.state.tagId}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>{' '}
                                <LoaderButton
                                    disabled={!this.validateFormTagId()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Search"
                                    loadingText="Searching…"
                                />
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <br/>
                <BootstrapTable
                    keyField="id"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={true}
                    striped
                    hover
                    condensed
                    noDataIndication={this.emptyIndication}
                />
            </div>
        )
    }
}