import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReportRepository from "../../services/ReportRepository";

export default class UserAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            deleteDialog: false,
            deleteUserEmail: null,
            notes: null
        }
    }

    deleteUserButton = () => {
        if (this.state.user) {
            ReportRepository.scheduleUserDelete(this.state.user.userId, {
                email: this.state.user.email,
                notes: this.state.notes
            })
                .then(response => {
                    if (!response.ok)
                        throw Error(response.statusText);
                    return response.json()
                }).then(deleteDetails => {
                let message = 'Removed user: ' + deleteDetails.userId + ' email: ' + deleteDetails.email;
                alert(message);
            }).finally(() => this.handleClose())
        }
    }

    handleClose = () => {
        this.setState({deleteDialog: false});
    }

    validateEmail = () => {
        return this.state.user.email == this.state.deleteUserEmail;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        return (<div>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <br/>
                        <Button onClick={() => this.setState({
                            deleteDialog: true,
                            user: this.state.user
                        })} variant="danger" size="lg"
                                block>
                            Delete user
                        </Button>
                        <br/>
                    </Col>
                </Row>
            </Container>
            <Modal style={{opacity: 1}} show={this.state.deleteDialog} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove user?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                        <p>Are you sure you want to delete user {this.state.user.userId} ({this.state.user.email})?<br/>
                            <b>This action cannot be undone.</b></p>
                        <input type="email" id={"deleteUserEmail"} placeholder={"Confirm user email"}
                               onChange={this.handleChange} style={{width: "400px"}} autoComplete="off"/>
                        <p><br/>Notes (Zendesk ticket etc):</p>
                        <textarea
                            maxLength={300}
                            className="form-control round InputActive textarea"
                            id="notes"
                            name="Notes test"
                            onChange={event => this.handleChange(event)}  />
                        <br/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Container className="d-flex justify-content-between">
                        <Row>
                            <Col><Button disabled={!this.validateEmail()} variant="outline-danger" size="lg"
                                         onClick={this.deleteUserButton}>
                                DELETE
                            </Button></Col>
                            <Col><Button variant="primary" size="lg" onClick={this.handleClose}>
                                CANCEL
                            </Button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </div>)
    }
}