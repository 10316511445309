import userRequest from "./RequestService";

class PetRepository {
    findPet(petId) {
        return userRequest('/pet/api/support/find', {
            method: 'POST',
            body: JSON.stringify({
                petId: petId
            })
        })
    }

    findPetByTagId(tagId) {
        return userRequest('/pet/api/support/find/tagId', {
            method: 'POST',
            body: JSON.stringify({
                petId: tagId
            })
        })
    }

    findUserPets(userId) {
        return userRequest('/pet/api/support/users/' + userId, {
            method: 'GET'
        });
    }

    getPetDetails(petId) {
        return userRequest('/pet/api/support/pets/' + petId,{
            method: 'GET'
        });
    }

    changePetOwner(petId, userId) {
        return userRequest('/pet/api/support/pets/'+petId+"/owner/"+userId, {
            method: 'PUT'
        })
    }

    removePetImage(petId, imageId) {
        return userRequest('/pet/api/support/pets/'+petId+'/profile/'+imageId, {
            method: 'DELETE'
        })
    }

    removeTagFromPet(petId, tagId) {
        return userRequest('/pet/api/support/pets/'+petId+'/tags/'+tagId+'/remove', {
            method: 'POST'
        })
    }
}

export default new PetRepository();