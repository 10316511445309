import React, {Component} from "react";
import {Button, Card, Col, Container, Form, FormControl, FormGroup, FormLabel, Image, Row} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./ImageView.css";
import ImageRepository from "../services/ImageRepository";
import Modal from "react-bootstrap/Modal";
import FeedRepository from "../services/FeedRepository";
import UserRepository from "../services/UserRepository";
import PetRepository from "../services/PetRepository";

export default class ImageView extends Component {
    constructor(props) {
        super(props);
        this.removeImageButton = this.removeImageButton.bind(this);
        this.contentBody = this.contentBody.bind(this);
        this.state = {
            isLoading: false,
            imageId: "",
            image: null,
            userProfilePhoto: null,
            deleteImageDialog: false,
            searchImageId: null
        }
    }


    imageButtonFormatter(cell, row, rowIndex, formatExtraData) {
        return <Button className="btn-sm" onClick={() => this.handlePetGalleryOpen(rowIndex)}>Show images</Button>
    }

    validateForm() {
        return this.state.imageId.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleImageSearch = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        this.setState({
            image: null,
            userProfilePhoto: null,
            searchImageId: null
        });
        ImageRepository.imageSearch(this.state.imageId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json();
        }).then(data => {
            if (data.value) {
                this.setState({image: data.value});
                if (this.state.image) {
                    ImageRepository.getImage(this.state.image.imageId).then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            this.setState({
                                userProfilePhoto: url
                            })
                        })
                    })
                }
            } else {
                this.setState({searchImageId: this.state.imageId})
            }
        }).catch(error => {
            alert(error);
        });

        this.setState({isLoading: false});
    }

    removeImageButton = () => {
        if (this.state.image) {
            return <div>
                <Button variant="outline-danger" size="lg"
                        onClick={() => this.setState({deleteImageDialog: true})}>Remove image</Button>
            </div>
        }
    }

    contentBody = () => {
        if (this.state.image) {
            return <div>
                <Row>
                    <Col md={6}>
                        <Container>
                            <Row>
                                <Col><p>Image id: <label>{this.state.image?.imageId}</label></p></Col>
                            </Row>
                            <Row>
                                <Col><p>Source: <label>{this.state.image?.type}</label></p></Col>
                            </Row>
                            <Row>
                                <Col><p>Type id: <label>{this.state.image?.typeId}</label></p></Col>
                            </Row>
                            <Row>
                                <Col><p>Created by: <label>{this.state.image?.userId}</label></p></Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col md={6}>
                        <center>{this.state.userProfilePhoto ?
                            <Image style={{width: 400, height: 400}} src={this.state.userProfilePhoto}
                                   thumbnail/> : ""}</center>
                    </Col>
                </Row>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Button variant="outline-danger" size="lg"
                                    onClick={() => this.setState({deleteImageDialog: true})}>Remove image</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        } else {
            if(this.state.searchImageId) {
                return <div><p>No image with id: {this.state.searchImageId}</p></div>
            }
        }

        return <div></div>
    }

    handleClose = () => {
        this.setState({
            deleteImageDialog: false
        })
    }

    deleteImage = () => {
        if(this.state.image?.type == "USER_PROFILE") {
            UserRepository.removeUserProfileImage(this.state.image.typeId, this.state.image?.imageId).then(this.handleClose());
        } else if(this.state.image?.type == "PET_PROFILE") {
            PetRepository.removePetImage(this.state.image.typeId, this.state.image?.imageId).then(this.handleClose());
        } else if (this.state.image?.type == "SOCIAL_POST") {
            FeedRepository.removePostImage(this.state.image.typeId, this.state.image?.imageId).then(this.handleClose());
        } else if (this.state.image?.type == "SOCIAL_COMMENT") {
            FeedRepository.removeCommentImage(this.state.image.typeId, this.state.image?.imageId).then(this.handleClose());
        } else {
            this.handleClose();
        }
    }

    render() {
        let contentBody = this.contentBody()
        return (
            <div>
                <Container>
                    <Row className="show-grid">
                        <Col md={12}>
                            <Form inline onSubmit={this.handleImageSearch}>
                                <FormGroup controlId="imageId">
                                    <FormLabel>Find image</FormLabel>{' '}
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        value={this.state.imageId}
                                        onChange={this.handleChange}/>
                                </FormGroup>{' '}
                                <LoaderButton
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Search"
                                    loadingText="Loading…"
                                />
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <br/>
                <Card>
                    <Card.Header>Image details</Card.Header>
                    <Card.Body>
                        {contentBody}
                    </Card.Body>
                </Card>

                <Modal style={{opacity: 1}} show={this.state.deleteImageDialog} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete {this.state.image?.imageId}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-danger" size="lg" onClick={this.deleteImage}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={this.handleClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
