import React, {Component} from "react";
import ReportTable from "./ReportTable";

export default class Report extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reportId: null
        };
    }

    setSelectedUser = (reportId) => {
        this.setState({reportId: reportId})
    }

    render() {
        return (
            <div>
                <ReportTable basePath={this.props.basePath} accessToken={this.props.accessToken}/>

            </div>
        );
    }
}