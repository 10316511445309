const API_URL = process.env.REACT_APP_BASE_PATH;
const API_KEY = process.env.REACT_APP_APP_ACCESS_TOKEN;

class AuthService {
    async login(username, password) {
        return new Promise((resolve, reject) => {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Timeshift", "3600");

            return fetch(API_URL + '/user/api/support/auth/sign-in', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + API_KEY
                }),
                body: JSON.stringify({
                    login: username,
                    password: password
                })
            }).then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                return response.json();
            }).then(data => {
                localStorage.setItem("user", JSON.stringify(data))
                localStorage.setItem('isAuthenticated', true);
                resolve(data);
            }).catch(error => {
                localStorage.removeItem('user');
                localStorage.setItem('isAuthenticated', false);
                reject("Refresh token invalid/not present");
            })
        })
    }

    async refresh() {
        console.log("Doing token refresh");
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token && user.token.refreshToken) {
            return fetch(API_URL + '/user/api/auth/v2/refresh', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + API_KEY
                }),
                body: JSON.stringify({
                    refreshToken: user.token.refreshToken
                })
            }).then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                return response.json()
            }).then(data => {
                localStorage.setItem("user", JSON.stringify(data))
                localStorage.setItem('isAuthenticated', true);
                return data;
            }).catch(error => {
                localStorage.removeItem('user');
                localStorage.setItem('isAuthenticated', false);
                throw new Error("Refresh token invalid/not present");
            })
        } else {
            this.logout();
            window.location.reload(false);
        }
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated')
    }
}

export default new AuthService();