import userRequest from "./RequestService";

class BackendTestRepository {
    latestResults() {
        return userRequest('/statistic/api/support/admin/backend/last', {
            method: 'GET'});
    }

}

export default new BackendTestRepository();