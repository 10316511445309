import userRequest from "./RequestService";

class UserRepository {
    getUserDetails(userId) {
        return userRequest('/user/api/support/user/signAs', {
            method: 'POST',
            body: JSON.stringify({
                userId: userId
            })
        })
    }

    async findUsers(email, lastName, id) {
        return userRequest('/user/api/support/find', {
            method: 'POST',
            body: JSON.stringify({
                email: email,
                lastName: lastName,
                userId: id
            })
        })
    }

    enablePawscoutPro(userId, expire) {
        return userRequest('/user/api/support/users/'+userId+'/enablePremium', {
            method: 'POST',
            body: JSON.stringify( {
                expire: expire
            })
        })
    }

    removeUserProfileImage(userId, imageId) {
        return userRequest('/user/api/support/user/'+userId+'/profile/'+imageId, {
            method: 'DELETE'
        })
    }

    getUserAuthentications(userId) {
        return userRequest('/user/api/support/users/'+userId+'/authentications', {
            method: 'GET'
        })
    }

    resetUserPassword(userId, email) {
        return userRequest('/user/api/support/users/'+userId+'/passwordReset', {
            method: 'POST',
            body: JSON.stringify( {
                email: email
            })
        })
    }

    changeEmail(userId, email) {
        return userRequest('/user/api/support/users/'+userId+'/email', {
            method: 'POST',
            body: JSON.stringify( {
                email: email
            })
        })
    }

    addUserEmail(userId, email) {
        return userRequest('/user/api/support/users/'+userId+'/addEmail', {
            method: 'POST',
            body: JSON.stringify( {
                email: email
            })
        })
    }
}

export default  new UserRepository();