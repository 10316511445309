import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';
import {Col, Row} from "react-bootstrap";
import {
    Chart as ChartJS,
    registerables
} from 'chart.js';
import {
    Chart, Bar
} from 'react-chartjs-2';
import ReportRepository from "../../services/ReportRepository";

ChartJS.register(
    ...registerables
);

export default class StatisticsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chartData: null,
            type: "ADD_TAG"
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.refreshData(this.state.type);
    }

    refreshData(type) {
        ReportRepository.getStatsFor(type)
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                return response.json()
            }).then(data => {
            let newChartData = {
                labels: data.labels,
                datasets: data.datasets
            }
            this.setState({chartData: newChartData})
        })
    }

    printChart = () => {
        let options = {
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
        };
        if (this.state.chartData) {
            return (
                <div>
                    <Bar
                        // ref={chartRef}
                        // type='bar'
                        options={options}
                        data={this.state.chartData}
                    />
                </div>
            )
        }

        return (
            <div></div>
        )
    }

    handleChange = event => {
        this.setState({
            chartData: null,
            [event.target.id]: event.target.value
        });
        this.refreshData(event.target.value);
    }

    render() {
        let chart = this.printChart();
        return (
            <div>
                <Form.Group as={Row} controlId="type">
                    <Form.Label column sm={6}>Type: </Form.Label>
                    <Col sm={6}>
                        <Form.Control as="select" onChange={this.handleChange}>
                            <option value="ADD_TAG">ADD_TAG</option>
                            <option value="ADD_QR">ADD_QR</option>
                            <option value="CREATE_PET">CREATE_PET</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                {chart}
            </div>
        );
    }


}