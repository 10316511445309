import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button, Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Modal from "react-bootstrap/Modal";
import FeedRepository from "../../services/FeedRepository";
import postMapper, {getImagesCard, getMessageCard, getVideoCard} from "../../helpers/PostHelper";
import overlayFactory from 'react-bootstrap-table2-overlay';
import LoaderButton from "../../components/LoaderButton";
import CommentTable from "./CommentTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

export default class FeedView extends Component {

    constructor(props) {
        super(props);

        this.userFormatter = this.userFormatter.bind(this);
        this.actionButtomFormatter = this.actionButtomFormatter.bind(this);
        this.state = {
            isLoading: false,
            data: [],
            deletePostDialog: false,
            deletePostId: null,
            nextToken: null,
            findPostId: '',
            findHashtag: ''
        };

        this.columns = [{
            dataField: 'id',
            text: 'Id',
            hidden: true
        }, {
            dataField: 'user',
            text: 'User',
            formatter: this.userFormatter
        }, {
            dataField: 'created',
            text: 'Created'
        }, {
            dataField: 'updated',
            text: 'Updated',
            hidden: true
        }, {
            dataField: 'type',
            text: 'Type'
        }, {
            dataField: 'message',
            text: 'Message'
        }, {
            dataField: 'images',
            text: 'Images',
            hidden: true
        }, {
            dataField: 'video',
            text: 'Video',
            hidden: true
        }, {
            dataField: 'petTag',
            text: 'Pet',
            hidden: true
        }, {
            dataField: 'location',
            text: 'Location',
            hidden: true
        }, {
            dataField: 'sharelink',
            text: 'Webview',
            hidden: true
        }, {
            dataField: 'likes',
            text: 'Likes'
        }, {
            dataField: 'comments',
            text: 'Comments'
        }, {
            dataField: 'button',
            text: 'Actions',
            formatter: this.actionButtomFormatter
        }]

        this.expandRow = {
            renderer: row => (
                <Container>
                    <Row>
                        <Col>
                            <Tabs defaultActiveKey={"basic"} transition={false} id={"post-tabs"}>
                                <Tab eventKey={"basic"} title={"General"}>
                                    <Container>
                                        <Row className="show-grid">
                                            <Col md={6}>
                                                <p>Id: <label>{row.id}</label></p>
                                                <p>Created: <label>{row.created}</label></p>
                                                <p>Updated: <label>{row.updated}</label></p>
                                                <p>Share link: <Button className="btn-sm" href={row.sharelink}
                                                                       target={"_blank"}>Open</Button></p>
                                            </Col>
                                            <Col md={6}>
                                                <p>Type: <label>{row.type}</label></p>
                                                <p>Likes: <label>{row.likes}</label></p>
                                                <p>Comments: <label>{row.comments}</label></p>
                                                {/*<p>Pet: {this.getPet(row.petTag)}</p>*/}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {getMessageCard(row)}
                                                {getImagesCard(row)}
                                                {getVideoCard(row)}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab eventKey={"comments"} title={"Comments"}>
                                    <Container>
                                        <Row>
                                            <CommentTable postId={row.id}/>
                                        </Row>
                                    </Container>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>

            ),
            showExpandColumn: true,
            expandByColumnOnly: true
        };
    }

    deletePost = () => {
        if (this.state.deletePostId) {
            FeedRepository.deletePost(this.state.deletePostId).then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                this.handleClose();
            }).catch(error => {
                alert(error);
                this.handleClose();
            })
        }
    }

    componentDidMount() {
        // this.loadFeed();
    }

    actionButtomFormatter(cell, row, rowIndex, formatExtraData) {
        return <div>
            <Button variant="outline-danger" size="lg"
                    onClick={() => this.setState({deletePostDialog: true, deletePostId: row.id})}>Delete post</Button>
        </div>
    }

    reload() {
        this.setState({isLoading: true});
        FeedRepository.getFeed(this.state.nextToken).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            var tableData = data.items.map(function (val) {
                if (val.type == 'POST') {
                    return postMapper(val);
                }
            }).filter(val => val !== undefined);
            this.setState({
                data: tableData,
                nextToken: data.nextToken
            });
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    loadFeed() {
        this.setState({isLoading: true});
        FeedRepository.getFeed(this.state.nextToken).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            var tableData = data.items.map(function (val) {
                if (val.type == 'POST') {
                    return postMapper(val);
                }
            }).filter(val => val !== undefined);
            this.setState({
                data: [...this.state.data, ...tableData],
                nextToken: data.nextToken
            });
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    userFormatter(cell, row, rowIndex, formatExtraData) {
        if (row.user) {
            return <p>{row.user.name} - {row.user.id}</p>
        }

    }

    loadMoreButton = () => {
        if (this.state.nextToken) {
            return (
                <Button className="btn-lg" onClick={() => this.loadFeed()}>Load more posts</Button>
            )
        } else {
            return (
                <Button className="btn-lg" onClick={() => this.reload()}>Load posts</Button>
            )
        }
    }

    handlePostSearch = event => {
        event.preventDefault();
        if (this.state.findPostId == 0)
            return
        FeedRepository.findPostOrComment(this.state.findPostId)
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                return response.json()
            }).then(find => {
            this.loadPost(find.postId)
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        });
    }

    handleHashtagPostSearch = event => {
        event.preventDefault();
        if (this.state.findHashtag == 0)
            return
        FeedRepository.findPostsByHashtag(this.state.findHashtag)
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                return response.json()
            }).then(data => {
            var tableData = data.items.map(function (val) {
                if (val.type == 'POST') {
                    return postMapper(val);
                }
            }).filter(val => val !== undefined);
            this.setState({
                data: tableData,
                nextToken: data.nextToken
            });
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    loadPost = postId => {
        FeedRepository.getPost(postId)
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                return response.json()
            }).then(post => {
            this.setState({
                data: [postMapper(post)],
                nextToken: null
            });
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    searchComponent = () => {
        return (
            <Container>
                <Row className="show-grid">
                    <Col md={6}>
                        <Form inline onSubmit={this.handlePostSearch}>
                            <FormGroup controlId="findPostId">
                                <FormLabel>Find by post/comment id</FormLabel>{' '}
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.findPostId}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>{' '}
                            <LoaderButton
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Search"
                                loadingText="Searching…"
                            />
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Form inline onSubmit={this.handleHashtagPostSearch}>
                            <FormGroup controlId="findHashtag">
                                <FormLabel>Find by hashtag</FormLabel>{' '}
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.findHashtag}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>{' '}
                            <LoaderButton
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Search"
                                loadingText="Searching…"
                            />
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }

    emptyIndication = () => {
        return (<div className="spinner">
            <div className="rect1"/>
            <div className="rect2"/>
            <div className="rect3"/>
            <div className="rect4"/>
            <div className="rect5"/>
        </div>);
    }

    handleClose = () => {
        this.setState({
            deletePostDialog: false,
            deletePostId: null,
        })
    }

    render() {
        let button = this.loadMoreButton()
        let search = this.searchComponent()
        return (
            <div>
                <Row>
                    <Col>{search}</Col>
                </Row>
                <Row>
                    <Col>{button}</Col>
                </Row>
                <BootstrapTable
                    keyField="id"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={true}
                    striped
                    hover
                    overlay={overlayFactory({spinner: true, background: 'rgba(192,192,192,0.3)'})}
                    expandRow={this.expandRow}
                />
                <Modal style={{opacity: 1}} show={this.state.deletePostDialog} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete {this.state.deletePostId}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-danger" size="lg" onClick={this.deletePost}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={this.handleClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const NoDataIndication = () => (
    <div className="spinner">
        <div className="rect1"/>
        <div className="rect2"/>
        <div className="rect3"/>
        <div className="rect4"/>
        <div className="rect5"/>
    </div>
);