import React, {Component} from "react";
import {Button} from "react-bootstrap";
import overlayFactory from "react-bootstrap-table2-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import FeedRepository from "../../services/FeedRepository";
import {commentMapper} from "../../helpers/PostHelper";
import Modal from "react-bootstrap/Modal";

export default class CommentTable extends Component {
    constructor(props) {
        super(props);

        this.userFormatter = this.userFormatter.bind(this);
        this.actionButtonFormatter = this.actionButtonFormatter.bind(this);
        this.state = {
            postId: this.props.postId,
            isLoading: false,
            data: [],
            deleteDialog: false,
            deleteCommentId: null,
        };

        this.columns = [{
            dataField: 'id',
            text: 'Id'
        }, {
            dataField: 'user',
            text: 'User',
            formatter: this.userFormatter
        }, {
            dataField: 'created',
            text: 'Created'
        }, {
            dataField: 'message',
            text: 'Message'
        }, {
            dataField: 'images',
            text: 'Images',
            hidden: true
        }, {
            dataField: 'button',
            text: 'Actions',
            formatter: this.actionButtonFormatter
        }];
    }

    handleClose = () => {
        this.setState({
            deleteDialog: false,
            deleteCommentId: null,
        })
    }

    componentDidMount() {
        this.loadComments();
    }

    loadComments() {
        this.setState({isLoading: true});
        FeedRepository.getPostComments(this.state.postId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            var tableData = data.data.map(function (val) {
                return commentMapper(val);
            })
            this.setState({data: tableData})
        }).catch(error => {
            alert(error);
        }).finally(() => {
            this.setState({isLoading: false});
        })
    }

    userFormatter(cell, row, rowIndex, formatExtraData) {
        if (row.user) {
            return <p>{row.user.name}<br/>{row.user.id}</p>
        }
    }

    actionButtonFormatter(cell, row, rowIndex, formatExtraData) {
        return <div>
            <Button variant="outline-danger" size="lg"
                    onClick={() => this.setState({deleteDialog: true, deleteCommentId: row.id})}>Delete comment</Button>
        </div>
    }

    deleteComment = () => {
        if (this.state.deleteCommentId) {
            FeedRepository.deletePostComment(this.state.postId, this.state.deleteCommentId).then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                this.handleClose();
            }).catch(error => {
                alert(error);
                this.handleClose();
            }).then(() => {
                this.loadComments();
            })
        }
    }

    render() {
        return (
            <div>
                <BootstrapTable
                    keyField="id"
                    data={this.state.data}
                    columns={this.columns}
                    remote={true}
                    deleteRow={true}
                    striped
                    hover
                    overlay={overlayFactory({spinner: true, background: 'rgba(192,192,192,0.3)'})}
                />
                <Modal style={{opacity: 1}} show={this.state.deleteDialog} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete comment {this.state.deleteCommentId}? </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-danger" size="lg" onClick={this.deleteComment}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={this.handleClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}