import React, {Component} from "react";
import {Col, Container, Row, Table} from "react-bootstrap";
import PetTable from "./PetTable";
import MobileTable from "./MobileTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import UserPostsTable from "./UserPostsTable";
import UserRepository from "../services/UserRepository";
import convertToDateTime from "../helpers/convert";
import FreeBatteryOrdersTable from "./support/FreeBatteryOrdersTable";
import Modal from "react-bootstrap/Modal";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import UserReviews from "./user/UserReviews";
import UserAdmin from "./user/UserAdmin";
import UserAuthentications from "./user/UserAuthentications";

export default class UserClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientId: this.props.clientId,
            isLoading: false,
            userDetails: {
                subscription: {}
            },
            createdDate: '',
            tab: this.props.tab,
            proUserId: null,
            proUserName: null,
            selectedDay: undefined
        }
        this.closeButton = this.props.closeButton.bind(this);
        this.openPet = this.props.openPet;
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.clientId !== this.props.clientId) {
    //         if(this.props.clientId == null){
    //             this.clearUser();
    //         } else {
    //             this.authenticateClient();
    //         }
    //     } else {
    //         this.authenticateClient();
    //     }
    // }

    componentDidMount() {
        this.authenticateClient();
    }

    clear = () => {
        this.authenticateClient();
    }

    authenticateClient = () => {
        this.setState({isLoading: true});
        UserRepository.getUserDetails(this.props.clientId).then(response => {
            if (!response.ok)
                throw Error(response.statusText);
            return response.json()
        }).then(data => {
            this.setState({userDetails: data.user})
            this.setState({createdDate: data.createdDate})
        }).catch(error => {
            alert(error);
        });
        this.setState({isLoading: false});
    }

    showUserDetails = () => {
        this.authenticateClient();
    }

    printAuthentication = () => {
        return (<Container><div></div></Container>)
    }

    printSubscription = () => {
        if (this.state.userDetails.subscription) {
            return (
                <Container>
                    <br/>
                    <Row className="show-grid">
                        <Col md={6}>
                            <p>Platform: <label>{this.state.userDetails.subscription.subscriptionType}</label></p>
                            <p>Expire
                                date: <label>{convertToDateTime(this.state.userDetails.subscription.expireDate)}</label>
                            </p>
                            <p>Batteries
                                ordered: <label>{this.state.userDetails.subscription.freeBatteriesAvailable ? ('False') : ('True')}</label>
                            </p>
                            <p>Promo code for
                                batteries: <label>{this.state.userDetails.subscription.promoCode ? this.state.userDetails.subscription.promoCode : ('None')}</label>
                            </p>
                        </Col>
                    </Row>
                </Container>
            )
        } else {
            return (
                <Container>
                    <Row>
                        <Col md={12} className={"text-center"}>
                            <br/>
                            <h3><Badge variant="secondary">No active subscription</Badge></h3> <br/>
                            <h3><Button variant="outline-danger" size="lg"
                                        onClick={() => this.setState({
                                            enableProDialog: true,
                                            proUserId: this.state.userDetails.userId,
                                            proUserName: this.state.userDetails.firstName + ' ' + this.state.userDetails.lastName
                                        })}>Enable Pawscout Pro</Button></h3>
                            <br/>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }

    handleClose = () => {
        this.setState({enableProDialog: false, proUserId: null, proUserName: null, selectedDay: undefined});
    }

    activatePawscoutSubscription = () => {
        if (this.state.proUserId && this.state.selectedDay) {
            UserRepository.enablePawscoutPro(this.state.proUserId, this.state.selectedDay.valueOf()).then(response => {
                if (!response.ok)
                    throw Error(response.statusText);
                this.handleClose();
                this.clear();
            }).catch(error => {
                alert(error);
            })
        }
    }

    handleDatePickerChange = (value, formattedValue) => {
        this.setState({
            proDate: value,
            proFormattedValue: formattedValue
        })
    }

    handleDayChange = (day) => {
        this.setState({selectedDay: day});
    }

    validateProDate = () => {
        return this.state.selectedDay > 0 && this.state.selectedDay > new Date();
    }

    adminTab = () => {
        if(this.state.userDetails.userId) {
            return <UserAdmin user={this.state.userDetails}/>
        } else
            return  <div></div>
    }

    render() {
        return (
            <div>
                <br/>
                <Container>
                    <Row>
                        <Col>
                            <Tabs defaultActiveKey="basic" transition={false} id="user-details-table">
                                <Tab eventKey="basic" title="Basic">
                                    <br/>
                                    <Container>
                                        <Row className="show-grid">
                                            <Col md={6}>
                                                <p>Client ID: <label>{this.state.userDetails.userId}</label>
                                                </p>
                                            </Col>
                                            <Col md={6}>
                                                <p>Account created: <label>{this.state.createdDate}</label>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="show-grid">
                                            <Col md={6}>
                                                <p>First
                                                    name: <label>{this.state.userDetails.firstName}</label>
                                                </p>
                                                <p>Middle
                                                    name: <label>{this.state.userDetails.middleName}</label>
                                                </p>
                                                <p>Last
                                                    name: <label>{this.state.userDetails.lastName}</label>
                                                </p>
                                            </Col>
                                            <Col md={6}>
                                                <p>Phone
                                                    number: <label>{this.state.userDetails.phoneNumber}</label>
                                                </p>
                                                <p>Email: <label>{this.state.userDetails.email}</label></p>
                                                <p>Account type:
                                                    <label>{this.state.userDetails.accountType}</label>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab eventKey="subscription" title="Subscription">
                                    {this.printSubscription()}
                                </Tab>
                                <Tab eventKey="authentication" title="Authentication">
                                    <UserAuthentications userId={this.state.clientId}/>
                                </Tab>
                                <Tab eventKey="review" title="Reviews">
                                    <UserReviews userId={this.state.clientId}/>
                                </Tab>
                                <Tab eventKey="admin" title="Admin">
                                    {this.adminTab()}
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Tabs defaultActiveKey="pet" transition={false} id="user-data">
                                <Tab eventKey="pet" title="Pets">
                                    <PetTable openPet={this.openPet} userId={this.state.clientId}/>
                                </Tab>
                                <Tab eventKey="devices" title="Devices">
                                    <MobileTable userId={this.state.clientId}/>
                                </Tab>
                                <Tab eventKey={"posts"} title={"Created posts"}>
                                    <UserPostsTable userId={this.state.clientId} openPet={this.openPet}/>
                                </Tab>
                                <Tab eventKey={"free_battery"} title={"Free battery orders"}>
                                    <FreeBatteryOrdersTable userId={this.state.clientId}/>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Button onClick={() => this.closeButton(this.state.tab)} variant="outline-danger" size="lg"
                                    block>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <Modal style={{opacity: 1}} show={this.state.enableProDialog} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enabling Pawscout Pro subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"text-center"}>Are you sure you want to enable <b>Pawscout Pro</b>
                            for {this.state.proUserName} ({this.state.proUserId})?
                            <br/><br/>
                            <div className={"text-center"}>
                                <DayPickerInput onDayChange={this.handleDayChange}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="d-flex justify-content-between">
                            <Row>
                                <Col><Button disabled={!this.validateProDate()} variant="outline-danger" size="lg"
                                             onClick={this.activatePawscoutSubscription}>
                                    Yes
                                </Button></Col>
                                <Col><Button variant="primary" size="lg" onClick={this.handleClose}>
                                    No
                                </Button></Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}